export default (editor, opts = {}) => {
    return {
        run(ed, s, options = {}) {
            const title = options.page?.get('title') || 'HTMLPAGE site';
            const description = options.page?.get('description') || 'HTMLPAGE site - Built using htmlpage.cn';
            const img = options.page?.get('ogImage') || '';
            const url = options.page?.get('ogUrl') || '';
            const favicon = options.page?.get('favicon') || 'https://htmlpage.cn/favicon.ico';
            const author = options.page?.get('author') || 'HTMLPAGE';
            const keywords = options.page?.get('keywords') || '';
            const css = options.page?.get('globalCss') || '';
            const head = options.page?.get('customHead') || '';
            const fonts = options.page?.get('fontLinks');
            return `<meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover">
                <title>${title}</title>
                <meta name="title" content="${title}">
                <meta name="description" content="${description}">
                <meta name="generator" content="HTMLPAGE">
                <meta name="author" content="${author}">
                ${keywords ? `<meta name="keywords" content="${keywords}">` : ''}

                <!-- Google / Search Engine Tags -->
                <meta itemprop="description" content="${description}">
                ${img ? `<meta itemprop="image" content="${img}">` : ''}

                <!-- Facebook Meta Tags -->
                <meta property="og:type" content="website">
                <meta property="og:title" content="${title}">
                <meta property="og:description" content="${description}">
                ${img ? `<meta property="og:image" content="${img}">` : ''}
                ${url ? `<meta property="og:url" content="${url}">` : ''}
                <meta property="og:site_name" content="${title}">

                <!-- Twitter Meta Tags -->
                <meta property="twitter:card" content="summary_large_image">
                ${url ? `<meta property="twitter:url" content="${url}">` : ''}
                <meta property="twitter:title" content="${title}">
                <meta property="twitter:description" content="${description}">
                ${img ? `<meta property="twitter:image" content="${img}">` : ''}

                <link rel="icon" href="${favicon}">
                
                <script type="application/ld+json">
                    {
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "description": "${description}",
                        "name": "${title}" ${img || url ? ',' : ''}
                        ${img ? `"image": "${img}"` : ''} ${img && url ? ',' : ''}
                        ${url ? `"url": "${url}"` : ''}
                    }
                </script>
                ${(fonts && JSON.parse(fonts).join('\n')) || ''}
                ${head}
                <style>
                ${css}
                </style>`;
        }
    }
}