import { basicSector, advancedSector, extraSector } from '../stylemanager';

/**
 * Build popup menu
 * 
 * @param {Object} $ Cashdom object
 * @param {String} pfx Style prefix
 */
export default (editor, $, pfx) => {
    const popup = $(`<div id="${pfx}popup"><div class="${pfx}popup-heading"><span>Block Settings</span><span class="close">x</span></div><div class="tab-c"><div class="tab active">Basic</div><div class="tab">Advanced</div><div class="tab">Interaction</div></div><div class="${pfx}popup-body"></div></div>`);

    const stm = editor.Styles;
    const sm = stm.render();
    sm.style = 'height: calc(100% - 160px);overflow: auto;';

    const popupBody = popup.find(`.${pfx}popup-body`);
    popupBody.append([editor.Selectors.render([]), sm]);

    const openStyleBtn = $(`<div class="fa fa-cogs ${pfx}styles-btn" style="display:none"></div>`);
    openStyleBtn.on('click', e => popup.get(0).style.display = 'block');
    editor.Commands.add('show-sm', () => popup.get(0).style.display = 'block');

    popup.find('.close').on('click', e => popup.get(0).style.display = 'none');

    const tabs = popup.find('.tab');
    tabs.on('click', ({ currentTarget }) => {
        if (!currentTarget.classList.contains('active')) {
            tabs.each((i, tab) => tab.classList.remove('active'));
            currentTarget.classList.add('active');
            const text = currentTarget.innerText.toLowerCase();
            switch (text) {
                case 'basic':
                    stm.getSectors().reset(basicSector);
                    break;
                case 'advanced':
                    stm.getSectors().reset(advancedSector);
                    break;
                case 'interaction':
                    stm.getSectors().reset(extraSector);
                    break;
                default:
                    break;
            }
            editor.trigger('component:toggled');
        }
    });

    return { popup, openStyleBtn };
}