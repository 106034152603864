import { typeTransform } from '../consts';

export default (editor) => {
    editor.Styles.addBuiltIn(typeTransform, {
        type: 'stack',
        layerSeparator: ' ',
        fromStyle(style, { property, name }) {
            const transform = style[name] || '';
            const sep = property.getLayerSeparator();
            return transform ? transform.split(sep).map(input => {
                const { name, value } = property.__parseFn(input);
                return { name, value };
            }) : [];
        },
        toStyle(values, { name }) {
            return { [name]: `${values.name}(${values.value})` };
        },
        properties: [
            {
                property: 'name',
                name: 'Type',
                type: 'select',
                default: 'translateX',
                full: true,
                options: [
                    { id: 'translateX', propValue: { min: -100, units: ['px', 'em', 'rem', 'vw', 'vh'] } },
                    { id: 'translateY', propValue: { units: ['px', 'em', 'rem', 'vw', 'vh'] } },
                    { id: 'translateZ', propValue: { units: ['px', 'em', 'rem', 'vw', 'vh'] } },
                    { id: 'scaleX', propValue: { min: -100, units: [] } },
                    { id: 'scaleY', propValue: { min: -100, units: [] } },
                    { id: 'scaleZ', propValue: { min: -100, units: [] } },
                    { id: 'rotateX', propValue: { min: 0, max: 360, units: ['deg', 'rad', 'grad'] } },
                    { id: 'rotateY', propValue: { min: 0, max: 360, units: ['deg', 'rad', 'grad'] } },
                    { id: 'rotateZ', propValue: { min: 0, max: 360, units: ['deg', 'rad', 'grad'] } },
                    { id: 'skewX', propValue: { min: 0, max: 360, units: ['deg', 'rad', 'grad'] } },
                    { id: 'skewY', propValue: { min: 0, max: 360, units: ['deg', 'rad', 'grad'] } },
                    { id: 'skewZ', propValue: { min: 0, max: 360, units: ['deg', 'rad', 'grad'] } },
                    { id: 'perspective', propValue: { min: -100, units: [] } },
                ],
                onChange({ property, to }) {
                    if (to.value) {
                        const option = property.getOption();
                        const props = { ...(option.propValue || {}) };
                        const propToUp = property.getParent().getProperty('value');
                        const unit = propToUp.getUnit();
                        if (!unit || props?.units.indexOf(unit) < 0) {
                            props.unit = props?.units[0] || '';
                        }
                        propToUp.up(props);
                    }
                }
            }, {
                property: 'value',
                type: 'slider',
                default: '0',
                full: true,
            },
        ]
    });
};