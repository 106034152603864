export default (editor, opts = {}) => {
    const pfx = editor.getConfig('stylePrefix');
    const modal = editor.Modal;
    const { $ } = editor;
    const mdlClass = `${pfx}mdl-dialog-sm`;
    const mdlClear = $(`<div id="clear-panel"><div style="font-size:15px;">${opts.textCleanCanvas}</div><br><button class="${pfx}btn-prim">Clear</button></div>`);

    mdlClear.find('button').on('click', () => {
        const selected = editor.getSelectedAll();
        selected && editor.selectRemove(selected);
        editor.runCommand('core:canvas-clear');
        editor.Modal.close();
        notyf.success('Canvas Cleared!');
    })

    return {
        run(editor) {
            const mdlDialog = $(`.${pfx}mdl-dialog`);
            mdlDialog.addClass(mdlClass);
            modal.setTitle('Canvas');
            modal.setContent($('#clear-panel').length ? $('#clear-panel') : mdlClear);
            modal.open();
            modal.getModel().once('change:open', () => {
                mdlDialog.removeClass(mdlClass);
                editor.stopCommand(this.id);
            });
        },

        stop() {
            modal.close();
        }
    }
}