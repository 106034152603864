import blocks from './blocks/';
import components from './components/';
import panels from './panels/';
import commands from './commands/';
import styles from './stylemanager/';
import cke from './cke';
import cloudinary from './cloudinary';
import devices from './panels/devices';
import optionsPn from './panels/options';
import left from './panels/left';
import right from './panels/right';
import popupBuilder from './panels/popup';
import toggle from './panels/toggle';
import { Share } from './panels/share'
import { dragElement, popupViewportPosition } from './utils/helpers';
import Adder, { initAdder } from './utils/Adder';
import AddWidget from './utils/AddWidget';
import { setEditorDimension } from './utils';

export default (editor, opts = {}) => {
    const options = {
        ...{
            // Use cloudinary file picker
            useCloudinary: true,
            // show panels
            showPanels: false,
            // static settings panel
            staticSettings: false,
            // remove settings animation
            settingsAnimation: true,
            // hide settings by default
            hideSettings: true,
            // options for extending blocks
            linkBlock: {},
            quoteBlock: {},
            textBlock: {},
            btnBlock: {},
            bldBlock: {},
            hrBlock: {},
            heroBlock: {},
            teamBlock: {},
            shapedividerBlock: {},
            sectionBlock: {},
            spanBlock: {},
            footerBlock: {},
            headerBlock: {},
            iconBlock: {},
            animateBlock: {},
            // Modal help title
            modalHelpLabel: '帮助支持',
            // Modal import title
            modalImportTitle: 'Import',
            // Modal import button text
            modalImportButton: 'Import',
            // Import description inside import modal
            modalImportLabel: 'HTML',
            // Default content to setup on import model open.
            // Could also be a function with a dynamic content return (must be a string)
            // eg. modalImportContent: editor => editor.getHtml(),
            modalImportContent: e => `<div>Add your html here, the canvas will be overwritten. Note that scripts will be ignored.</div><style>/*Add your styles here*/</style>`,
            // Code viewer (eg. CodeMirror) options
            importViewerOptions: {},
            // Confirm text before cleaning the canvas
            textCleanCanvas: 'Are you sure to clean the canvas?',
            // Callbacks
            onSave(ed) {
                //ed.runCommand('take-screenshot');
                ed.store();
            },
            // Hover delay, lower value makes hover update faster
            hoverDelay: 250,
            // Add widget default opts overrides
            optsWidget: {
                blocks: {
                    add: ['column1', 'column2', 'column3', 'column3-7', 'countdown', 'h-navbar'], // Section blocks
                },
            },
            // Determine hoverable components
            toHover(component) {
                const ignore = ['Cell', 'wrapper', 'Card', 'icon', 'section', 'grid-cell', 'Navbar Container', 'Navbar Menu', 'lory-frame', 'lory-slides', 'lory-slide', 'lory-prev', 'lory-next', 'tab', 'tab-contents', 'tab-content'];
                return !(component.get('addable') ||
                    ignore.indexOf(component.get('type')) > -1 ||
                    ignore.indexOf(component.get('custom-name')) > -1 ||
                    ignore.indexOf(component.get('name')) > -1 ||
                    ignore.indexOf(component.get('label')) > -1)
            },
            // RTE position
            position: 'center',
            // CKE4 options
            options: {
                startupFocus: true,
                extraAllowedContent: '*(*);*{*}', // Allows any class and any inline style
                allowedContent: true, // Disable auto-formatting, class removing, etc.
                enterMode: CKEDITOR.ENTER_BR,
                shiftEnterMode: CKEDITOR.ENTER_P,
                extraPlugins: 'sharedspace,justify,colorbutton,panelbutton',
                toolbar: [
                    ['Bold', 'Italic', 'Underline', 'Strike'],
                    { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
                    { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                    { name: 'links', items: ['Link', 'Unlink'] },
                    { name: 'colors', items: ['TextColor', 'BGColor'] }
                ],
            },
            // App version
            version: 'v1.0.44b',
        },
        ...opts
    };

    //initialize components
    components(editor, options);
    //initialize blocks
    blocks(editor, options);
    //initialize commands
    commands(editor, options);
    //initialize panels
    panels(editor, options);
    //initialize cke4
    cke(editor, options);
    //initialize cloudinary
    options.useCloudinary && cloudinary(editor, options);
    //add adder API
    editor.Utils.Adder = Adder;

    //make forms netlify compatible
    const domc = editor.Components;
    const typeForm = domc.getType('form').model;
    domc.addType('form', {
        model: {
            initTraits() {
                typeForm.prototype.initTraits.apply(this, arguments);
                const tr = this.get('traits');
                [{
                    name: 'name',
                    default: 'form-name'
                }, {
                    type: 'checkbox',
                    name: 'data-netlify',
                    label: 'Netlify'
                }, {
                    type: 'checkbox',
                    name: 'netlify-honeypot',
                    label: 'Honeypot',
                    valueTrue: 'bot-field'
                }, {
                    type: 'checkbox',
                    name: 'data-netlify-recaptcha',
                    label: 'CAPTCHA 2'
                }].forEach(trait => tr.push(trait));
                this.set('traits', tr);
            }
        }
    });

    // Add icons to all component types
    domc.getTypes().map(c => c.id).forEach(type => {
        const typeComp = domc.getType(type);
        const typeOpt = typeComp.model;
        try {
            domc.addType(type, {
                model: {
                    initToolbar() {
                        typeOpt.prototype.initToolbar.apply(this, arguments);
                        const tb = this.get('toolbar');
                        const tbAddExists = tb.some(item => item.command === 'add-block');
                        const tbSmExists = tb.some(item => item.command === 'show-sm');

                        if (type !== 'wrapper' && !tbAddExists) {
                            tb.unshift({
                                command: 'add-block',
                                label: '<i class="fa fa-floppy-o"></i>',
                                attributes: {
                                    'data-tooltip-pos': 'bottom',
                                    'data-tooltip': 'Save Block'
                                },
                            });
                            this.set('toolbar', tb);
                        }

                        if (!tbSmExists && !options.showPanels) {
                            tb.unshift({
                                command: 'show-sm',
                                label: '<i class="fa fa-cog"></i>',
                                attributes: {
                                    'data-tooltip-pos': 'bottom',
                                    'data-tooltip': 'Style Manager'
                                },
                            });
                            this.set('toolbar', tb);
                        }
                    }
                }
            });
        } catch (error) {
        }
    });

    editor.on('storage:end:load', res => {
        const removeHolders = (components) => {
            components.each(component => {
                if (component) {
                    if (component.get('type') === 'holder') {
                        component.remove();
                    } else {
                        removeHolders(component.get('components'));
                    }
                }
            });
        }
        removeHolders(editor.getComponents());
    });

    const btnExp = editor.$('<button id="export-zip-code" class="htmlpage-btn-prim">导出压缩包.zip</button>');

    editor.on('run:export-template', () => {
        const btn = editor.$('#export-zip-code');
        if (btn.length) {
            editor.Modal.getContentEl().appendChild(btn.get(0));
        }
        else {
            editor.Modal.getContentEl().appendChild(btnExp.get(0));
            btnExp.on('click', () => editor.runCommand('gjs-export-zip', { save: true }));
        }
    });

    const { $ } = editor;
    const pn = editor.Panels;
    const pfx = editor.getConfig().stylePrefix;
    const canvas = editor.Canvas;
    const adderOpts = {
        prefix: pfx,
        mousePosFetcher: canvas.getMouseRelativePos,
        hoverDelay: options.hoverDelay,
        ifBlocksOpen() {
            notyf.open({
                type: 'warning',
                message: 'Blocks panel already open'
            });
        }
    };
    let bmt = false;
    let lmt = false;

    editor.on('load', () => {
        const container = $(editor.getContainer());
        const tools = $(editor.Canvas.getToolsEl());
        const toolbar = $(editor.Canvas.getToolsEl());
        const cv = $(editor.Canvas.getElement());
        const { showPanels, staticSettings, settingsAnimation, hideSettings, version } = options;
        const { width } = editor.Canvas.getRect();

        //initialize styles
        styles(editor, options);
        ///remove buttons
        showPanels && pn.removeButton('views', 'images');
        showPanels || pn.removeButton('views', 'open-sm');
        //open block manager to render
        editor.runCommand('open-blocks');
        //open style manager if showPanels
        const openSm = pn.getButton('views', 'open-sm');
        showPanels && openSm && openSm.set('active', 1);
        //conditional class
        showPanels && $('#gjs').removeClass(`${pfx}paneless`);
        // Show logo with the version
        $(`.${pfx}logo-version`).html(version);
        //'v' + grapesjs.version;

        // adder el
        const adderEl = $(`<div className="${pfx}adder"></div>`);
        tools.append(adderEl);
        const getAdderEl = () => adderEl.get(0);
        editor.Canvas.getAdderEl = getAdderEl;

        adderOpts.posFetcher = canvas.getCanvasView().getElementPos.bind(canvas.getCanvasView());
        adderOpts.appendTo = canvas.getGlobalToolsEl();
        if (editor.Utils.DynamicAdder)
            editor.Utils.DynamicAdder.setOptions({ ...adderOpts, ...options.optsWidget });
        else
            editor.Utils.DynamicAdder = AddWidget.init(editor, { ...adderOpts, ...options.optsWidget });

        if (!showPanels) {
            toolbar.css('z-index', 2);

            const { popup, openStyleBtn } = popupBuilder(editor, $, pfx);
            const popupHeading = popup.find(`.${pfx}popup-heading span`).first();

            !staticSettings && toolbar.append([popup, openStyleBtn]);
            staticSettings && cv.append([popup, openStyleBtn]);

            // make popup heading interraction point for positioning the popup
            dragElement(popup.get(0), `.${pfx}popup-heading`);

            // recalculate popup position
            editor.on('component:selected component:drag:end', component => {
                const { style, classList } = popup.get(0);
                const un = 'px';
                if (hideSettings) style.display = 'none';
                if (!staticSettings) {
                    const selectedEl = (component.getEl && component.getEl()) ||
                        editor.getSelected().getEl && editor.getSelected().getEl() ||
                        toolbar.get(0);

                    const { top, left } = popupViewportPosition(editor, popup.get(0), selectedEl, {
                        offsetTop: 5,
                        offsetRight: 5
                    });

                    style.top = top + un;
                    style.left = left + un;
                    settingsAnimation && classList.add(`${pfx}zoomin`);
                } else {
                    settingsAnimation && classList.add(`${pfx}zoomin`);
                    style.top || (style.top = 100 + un);
                    style.left || (style.left = (width - (popup.get(0).offsetWidth || 260) - 80) + un);
                }
                popupHeading.text(`${(component.getName && component.getName()) ||
                    (editor.getSelected() && editor.getSelected().getName())} Settings`);
            });

            // make popup visible again to allow it's position to be recalculated
            editor.on('component:deselected run:canvas-clear', component => {
                if (!staticSettings) {
                    const { style, classList } = popup.get(0);
                    style.display = 'block';
                    settingsAnimation && classList.remove(`${pfx}zoomin`);
                }
                editor.Utils.DynamicAdder.deselect();
            });

            // some helpers
            const collapse = () => {
                optionsPanel.hide();
                devicePanel.hide();
                rightPanel.hide();
                $(`#${pfx}breadcrumbs`).hide();
                leftPanel.css('display', 'none');
            }

            const uncollapse = () => {
                optionsPanel.show();
                devicePanel.show();
                rightPanel.show();
                $(`#${pfx}breadcrumbs`).show();
                leftPanel.css('display', '');
            }

            // hide panels in preview mode
            editor.on('run:preview', () => {
                collapseBtn.hide();
                collapse();
                editor.Utils.DynamicAdder.deselect();
                editor.Utils.DynamicAdder.disable();
            });
            // show panels when exit preview mode
            editor.on('stop:preview', () => {
                collapseBtn.show();
                uncollapse();
                const rul = $('.rul_wrapper');
                const op = $(`.${pfx}pn-options`);
                if ((!rul.length || rul.css('display') === 'none') && (!op || op.css('display') === 'none'))
                    editor.Utils.DynamicAdder.enable();
            });

            // make panels collapsible
            const collapseBtn = $(`<div class="${pfx}collapse-btn ${pfx}one-bg ${pfx}pn-float close"></div>`);
            const optionsPanel = optionsPn(editor, options);
            const devicePanel = devices(editor, options);
            const leftPanel = left(editor, options);
            const rightPanel = right(editor, options);
            const toggleBtn = toggle(editor, $, pfx);
            const share = new Share(editor);
            const shareEl = share.render();
            container.append(shareEl);

            // make left panel dragabble
            dragElement(leftPanel.get(0), '.drag-trigger');

            collapseBtn.on('click', function () {
                if (collapseBtn.hasClass('close')) {
                    collapse();
                    toggleBtn.show();
                    const rul = $('.rul_wrapper');
                    if ((!rul.length || rul.css('display') === 'none'))
                        editor.Utils.DynamicAdder.enable();
                } else {
                    uncollapse();
                    toggleBtn.hide();
                    editor.Utils.DynamicAdder.deselect();
                    editor.Utils.DynamicAdder.disable();
                }
                collapseBtn.toggleClass('close');
                collapseBtn.toggleClass('open');
            });
            //move panels around
            container.append([collapseBtn, optionsPanel, devicePanel, leftPanel, rightPanel]);
        }
        // move traits
        const traitsSector = $(`<div class="${pfx}sm-sector ${pfx}one-bg no-select" style="max-height: calc(100% - 125px);overflow: auto;"><div class="${pfx}sm-sector-title ${pfx}sm-title"><div class="icon-settings fa fa-cog"></div><div class="${pfx}sm-sector-label">Settings</div></div><div class="${pfx}sm-properties" style="display: none;"></div></div>`);
        const traitsProps = traitsSector.find(`.${pfx}sm-properties`);
        traitsProps.append(editor.Traits.render());
        $(`.${pfx}sm-sectors`).before(traitsSector);
        traitsSector.find(`.${pfx}sm-title`).on('click', function () {
            let traitStyle = traitsProps.get(0).style;
            let hidden = traitStyle.display == 'none';
            if (hidden) traitStyle.display = 'block';
            else traitStyle.display = 'none';
        });
    });

    // close blocks panel  after 300ms when user starts dragging block
    editor.on('block:drag:start', () => {
        !options.showPanels && setTimeout(() => {
            editor.stopCommand('open-blocks');
            pn.getPanel('views')
                .buttons.get('open-blocks')
                .set('active', 0);
        }, 300);
        editor.stopCommand('adder');
    });

    editor.on('component:drag:start', component => {
        editor.stopCommand('adder');
    });

    editor.on('component:selected', component => {
        const elem = component || editor.getSelected();
        elem && initAdder(elem, editor);
    });

    editor.on('component:mount', component => {
        const { toHover } = options;
        const el = component.getEl();
        if (!editor.Utils.DynamicAdder) {
            editor.Utils.DynamicAdder = AddWidget.init(editor, { ...adderOpts, ...options.optsWidget });
        }
        if (toHover(component)) {
            editor.Utils.DynamicAdder.addHover(el);
        } else {
            editor.Utils.DynamicAdder.ignoreHover(el);
        }
    });

    editor.on('component:remove', component => {
        editor.Utils.DynamicAdder?.removeEl(component.getEl());
    });

    editor.on('run:ruler-visibility', () => {
        editor.Utils.DynamicAdder?.disable();
    });

    editor.on('stop:ruler-visibility', () => {
        const op = $(`.${pfx}pn-options`);
        if ((!op.length || op.css('display') === 'none'))
            editor.Utils.DynamicAdder?.enable();
    });

    editor.on('block:drag:start run:set-device-desktop run:set-device-tablet run:set-device-mobile run:smoothresize styleManager:change', () => {
        editor.Utils.DynamicAdder?.deselect();
    });

    // Blocks filter
    const filter = (query) => {
        const bm = editor.Blocks;
        const all = bm.getAll();
        let filter = all;
        filter = all.filter(block => {
            return block.get('label').toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
        bm.render(filter);
    };

    const blocksHeader = $(`<div><div class="${pfx}pn-content-header">网页模块</div><div style="padding:10px;" class="${pfx}assets-search"><input class="tm-input sm" style="color:white;width:100%;padding:10px;border-radius:5px;" type="search" placeholder="搜索模块..."></div></div>`);
    const input = blocksHeader.find('input');
    input.on('keyup', e => filter(e.target.value));

    // Add title to block, layers and pages
    editor.on('run:open-blocks', () => {
        bmt || $(`.${pfx}blocks-cs`).parent()
            .prepend(blocksHeader);
        bmt = true;
    });

    editor.on('run:open-layers', () => {
        lmt || $(`.${pfx}layer__t-wrapper`).parent()
            .prepend($(`<div class="${pfx}pn-content-header">图层面板</div>`));
        lmt = true;
    });

    editor.on('run:open-pages', () => {
        $('.pages-wrp').prepend($(`<div class="${pfx}pn-content-header" style="padding-top: 8px; padding-left: 0">页面管理</div>`));
    });

    editor.on('page:select', page => {
        // editor.addStyle(page.get('globalCss') || '');
        const doc = editor.Canvas.getDocument();
        const fontLinks = page.get('fontLinks');
        const storeLinks = fontLinks ? JSON.parse(fontLinks) : [];

        doc.querySelectorAll('.font-link').forEach(el => el.remove());
        storeLinks.forEach(link => {
            const linkEl = $(link).get(0);
            if (linkEl.nodeName === 'LINK') {
                linkEl.classList.add('font-link');
                doc.head.appendChild(linkEl);
            }
        });

        const fonts = page.get('fonts');
        const storeFonts = fonts ? JSON.parse(fonts) : [];
        const prop = editor.StyleManager.getProperty('typography', 'font-family');
        prop.setOptions([...prop.getOptions().slice(0, 13), ...storeFonts]);
    });

    setEditorDimension(editor)
};