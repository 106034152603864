import {
    cmdDeviceDesktop,
    cmdDeviceTablet,
    cmdDeviceMobile,
    cmdClear,
    cmdHelp,
    cmdImport,
    cmdAssets,
    cmdSettings,
    cmdPublish,
    cmdZoomIn,
    cmdZoomOut,
    cmdShare,
} from '../consts';

export default (editor, opts = {}) => {
    const pn = editor.Panels;
    const um = editor.UndoManager;
    const eConfig = editor.getConfig();
    const pfx = eConfig.stylePrefix;
    const crc = 'create-comp';
    const mvc = 'move-comp';
    const swv = 'sw-visibility';
    const expt = 'export-template';
    const osm = 'open-sm';
    const otm = 'open-tm';
    const ola = 'open-layers';
    const obl = 'open-blocks';
    const ful = 'fullscreen';
    const prv = 'preview';

    eConfig.showDevices = 0;

    pn.getPanels().reset([{
        id: 'devices',
        buttons: [{
            id: cmdDeviceDesktop,
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M13.5 3h-11a.5.5 0 0 0-.5.5V11h12V3.5a.5.5 0 0 0-.5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11z"/><path d="M0 12h16v.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5V12z"/></svg>`,
            command: cmdDeviceDesktop,
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': '9x16',
            },
            togglable: 0,
            active: 1,
        }, {
            id: cmdDeviceTablet,
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M12 1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/><path fill-rule="evenodd" d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>`,
            command: cmdDeviceTablet,
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': '3x4',
            },
            togglable: 0
        }, {
            id: cmdDeviceMobile,
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11 1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/><path fill-rule="evenodd" d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>`,
            command: cmdDeviceMobile,
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': '1x1',
            },
            togglable: 0
        }, {
            id: cmdZoomOut,
            label: `<svg width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/></svg>`,
            command: cmdZoomOut,
            context: cmdZoomOut,
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Zoom Out',
            },
        }, {
            id: cmdZoomIn,
            label: `<svg width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/></svg>`,
            command: cmdZoomIn,
            context: cmdZoomOut,
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Zoom In',
            },
        }]
    }, {
        id: 'commands',
        buttons: [{}],
    }, {
        id: 'options',
        buttons: [{
            id: 'logo',
            context: 'logo',
            attributes: {
                style: 'padding:0'
            },
            label: `<img style="padding-top: 5px" src="block-composer.svg" height="20px" alt="logo">`
        }, {
            id: 'help',
            className: 'fa fa-question-circle-o',
            command: e => e.runCommand(cmdHelp),
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Help'
            }
        }, {
            id: swv,
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path d="M0 0h.969v.5H1v.469H.969V1H.5V.969H0V0zm2.844 1h-.938V0h.938v1zm1.875 0H3.78V0h.938v1zm1.875 0h-.938V0h.938v1zm.937 0V.969H7.5V.5h.031V0h.938v.5H8.5v.469h-.031V1H7.53zm2.813 0h-.938V0h.938v1zm1.875 0h-.938V0h.938v1zm1.875 0h-.938V0h.938v1zM15.5 1h-.469V.969H15V.5h.031V0H16v.969h-.5V1zM1 1.906v.938H0v-.938h1zm6.5.938v-.938h1v.938h-1zm7.5 0v-.938h1v.938h-1zM1 3.78v.938H0V3.78h1zm6.5.938V3.78h1v.938h-1zm7.5 0V3.78h1v.938h-1zM1 5.656v.938H0v-.938h1zm6.5.938v-.938h1v.938h-1zm7.5 0v-.938h1v.938h-1zM.969 8.5H.5v-.031H0V7.53h.5V7.5h.469v.031H1v.938H.969V8.5zm1.875 0h-.938v-1h.938v1zm1.875 0H3.78v-1h.938v1zm1.875 0h-.938v-1h.938v1zm1.875-.031V8.5H7.53v-.031H7.5V7.53h.031V7.5h.938v.031H8.5v.938h-.031zm1.875.031h-.938v-1h.938v1zm1.875 0h-.938v-1h.938v1zm1.875 0h-.938v-1h.938v1zm1.406 0h-.469v-.031H15V7.53h.031V7.5h.469v.031h.5v.938h-.5V8.5zM0 10.344v-.938h1v.938H0zm7.5 0v-.938h1v.938h-1zm8.5-.938v.938h-1v-.938h1zM0 12.22v-.938h1v.938H0zm7.5 0v-.938h1v.938h-1zm8.5-.938v.938h-1v-.938h1zM0 14.094v-.938h1v.938H0zm7.5 0v-.938h1v.938h-1zm8.5-.938v.938h-1v-.938h1zM.969 16H0v-.969h.5V15h.469v.031H1v.469H.969v.5zm1.875 0h-.938v-1h.938v1zm1.875 0H3.78v-1h.938v1zm1.875 0h-.938v-1h.938v1zm.937 0v-.5H7.5v-.469h.031V15h.938v.031H8.5v.469h-.031v.5H7.53zm2.813 0h-.938v-1h.938v1zm1.875 0h-.938v-1h.938v1zm1.875 0h-.938v-1h.938v1zm.937 0v-.5H15v-.469h.031V15h.469v.031h.5V16h-.969z"/></svg>`,
            command: swv,
            context: swv,
            active: 1,
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Guides'
            }
        }, {
            id: ful,
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.464 10.536a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3.5a.5.5 0 0 1-.5-.5v-3.5a.5.5 0 0 1 .5-.5z"/><path fill-rule="evenodd" d="M5.964 10a.5.5 0 0 1 0 .707l-4.146 4.147a.5.5 0 0 1-.707-.708L5.257 10a.5.5 0 0 1 .707 0zm8.854-8.854a.5.5 0 0 1 0 .708L10.672 6a.5.5 0 0 1-.708-.707l4.147-4.147a.5.5 0 0 1 .707 0z"/><path fill-rule="evenodd" d="M10.5 1.5A.5.5 0 0 1 11 1h3.5a.5.5 0 0 1 .5.5V5a.5.5 0 0 1-1 0V2h-3a.5.5 0 0 1-.5-.5zm4 9a.5.5 0 0 0-.5.5v3h-3a.5.5 0 0 0 0 1h3.5a.5.5 0 0 0 .5-.5V11a.5.5 0 0 0-.5-.5z"/><path fill-rule="evenodd" d="M10 9.964a.5.5 0 0 0 0 .708l4.146 4.146a.5.5 0 0 0 .708-.707l-4.147-4.147a.5.5 0 0 0-.707 0zM1.182 1.146a.5.5 0 0 0 0 .708L5.328 6a.5.5 0 0 0 .708-.707L1.889 1.146a.5.5 0 0 0-.707 0z"/><path fill-rule="evenodd" d="M5.5 1.5A.5.5 0 0 0 5 1H1.5a.5.5 0 0 0-.5.5V5a.5.5 0 0 0 1 0V2h3a.5.5 0 0 0 .5-.5z"/></svg>`,
            command: ful,
            context: ful,
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Fullscreen'
            }
        }, {
            id: cmdClear,
            className: 'fa fa-trash-o',
            command: e => e.runCommand(cmdClear),
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Clear',
            },
        }, {
            id: 'undo',
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M6.104 2.396a.5.5 0 0 1 0 .708L3.457 5.75l2.647 2.646a.5.5 0 1 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z"/><path fill-rule="evenodd" d="M2.75 5.75a.5.5 0 0 1 .5-.5h6.5a2.5 2.5 0 0 1 2.5 2.5v5.5a.5.5 0 0 1-1 0v-5.5a1.5 1.5 0 0 0-1.5-1.5h-6.5a.5.5 0 0 1-.5-.5z"/></svg>`,
            command: e => {
                um.hasUndo() ? undoBtn.set('attributes', {
                    style: 'color: #66c9de'
                }) : undoBtn.set('attributes', {
                    style: 'color: inherit'
                });
                um.hasUndo() && um.undo();
            },
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Undo',
            },
        }, {
            id: 'redo',
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M9.896 2.396a.5.5 0 0 0 0 .708l2.647 2.646-2.647 2.646a.5.5 0 1 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z"/><path fill-rule="evenodd" d="M13.25 5.75a.5.5 0 0 0-.5-.5h-6.5a2.5 2.5 0 0 0-2.5 2.5v5.5a.5.5 0 0 0 1 0v-5.5a1.5 1.5 0 0 1 1.5-1.5h6.5a.5.5 0 0 0 .5-.5z"/></svg>`,
            command: e => um.hasRedo() && um.redo(),
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Redo',
            },
        }, {
            id: prv,
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M10.804 8L5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"/></svg>`,
            context: prv,
            command: prv,
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Preview',
            },
        }, {
            id: cmdImport,
            command: e => e.runCommand(cmdImport),
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8zM5 4.854a.5.5 0 0 0 .707 0L8 2.56l2.293 2.293A.5.5 0 1 0 11 4.146L8.354 1.5a.5.5 0 0 0-.708 0L5 4.146a.5.5 0 0 0 0 .708z"/><path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 2z"/></svg>`,
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Import',
            },
        }, {
            id: 'save',
            command: e => opts.onSave(e),
            className: 'fa fa-floppy-o',
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Save',
            },
        }, {
            id: expt,
            className: 'fa fa-code',
            command: e => e.runCommand(expt),
            attributes: {
                'data-tooltip-pos': 'bottom',
                'data-tooltip': 'Code',
            },
        },
        // {
        //     id: 'publish',
        //     command: cmdShare,
        //     label: '<i class="fa fa-paper-plane-o"></i> Share',
        //     className: 'gjs-btn-prim',
        //     attributes: {
        //         style: 'font-size: 15px'
        //     }
        // }
    ],
    }, {
        id: 'views',
        buttons: [{
            id: 'settings',
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z"/><path fill-rule="evenodd" d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z"/></svg>`,
            attributes: {
                'data-tooltip-pos': 'right',
                'data-tooltip': 'Settings',
            },
            command: cmdSettings
        }, {
            id: osm,
            label: `<svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.067 6.067 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.118 8.118 0 0 1-3.078.132 3.659 3.659 0 0 1-.562-.135 1.382 1.382 0 0 1-.466-.247.714.714 0 0 1-.204-.288.622.622 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896.126.007.243.025.348.048.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04zM4.705 11.912a1.23 1.23 0 0 0-.419-.1c-.246-.013-.573.05-.879.479-.197.275-.355.532-.5.777l-.105.177c-.106.181-.213.362-.32.528a3.39 3.39 0 0 1-.76.861c.69.112 1.736.111 2.657-.12.559-.139.843-.569.993-1.06a3.122 3.122 0 0 0 .126-.75l-.793-.792zm1.44.026c.12-.04.277-.1.458-.183a5.068 5.068 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.59 1.927-5.566 4.66-7.302 6.792-.442.543-.795 1.243-1.042 1.826-.121.288-.214.54-.275.72v.001l.575.575zm-4.973 3.04.007-.005a.031.031 0 0 1-.007.004zm3.582-3.043.002.001h-.002z"/></svg>`,
            command: osm,
            togglable: 0,
            attributes: {
                title: 'Open Style Manager',
                'data-tooltip-pos': 'bottom',
            },
        }, 
        {
            id: 'images',
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16" style="fill:none;stroke:currentColor;"><path d="M10.648 6.646a.5.5 0 0 1 .577-.093l4.777 3.947V15a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1v-2l3.646-4.354a.5.5 0 0 1 .63-.062l2.66 2.773 3.71-4.71z"/><path fill-rule="evenodd" d="M4.5 5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/></svg>`,
            attributes: {
                'data-tooltip-pos': 'right',
                'data-tooltip': 'Assets',
            },
            command: cmdAssets
        }, 
        {
            id: 'open-pages',
            label: `<svg width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16"><path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/></svg>`,
            attributes: {
                'data-tooltip-pos': 'right',
                'data-tooltip': 'Pages',
            },
            command: 'open-pages'
        }, {
            id: ola,
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M3.188 8L.264 9.559a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882L12.813 8l-1.063.567L14.438 10 8 13.433 1.562 10 4.25 8.567 3.187 8z"/><path fill-rule="evenodd" d="M7.765 1.559a.5.5 0 0 1 .47 0l7.5 4a.5.5 0 0 1 0 .882l-7.5 4a.5.5 0 0 1-.47 0l-7.5-4a.5.5 0 0 1 0-.882l7.5-4zM1.563 6L8 9.433 14.438 6 8 2.567 1.562 6z"/></svg>`,
            attributes: {
                'data-tooltip-pos': 'right',
                'data-tooltip': 'Layers',
            },
            command: ola
        }, {
            id: obl,
            label: `<svg width="1em" height="1em" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/></svg>`,
            command: obl,
            attributes: {
                'data-tooltip-pos': 'right',
                'data-tooltip': 'Blocks',
            },
            active: 1
        }],
    }]);

    const undoBtn = pn.getButton('options', 'undo');
    const redoBtn = pn.getButton('options', 'redo');
    const saveBtn = pn.getButton('options', 'save');
    const saveIcon = saveBtn.get('className');

    editor.on('change:changesCount', () => {
        um.hasUndo() ? undoBtn.set('attributes', {
            style: 'color: #66c9de'
        }) : undoBtn.set('attributes', {
            style: 'color: inherit'
        });
        um.hasRedo() ? redoBtn.set('attributes', {
            style: 'color: #66c9de'
        }) : redoBtn.set('attributes', {
            style: 'color: inherit'
        });
        editor.getDirtyCount() > 0 ?
            saveBtn.set('className', saveIcon + ' glow') : saveBtn.set('className', saveIcon);
    });
}