export default {
    run(editor, sender, opts) {
        const opt = opts || {};
        const el = opt.el || '';
        const canvas = editor.Canvas;
        let { canvasAdder } = this;
        const options = opt.options || {};
        const canvasView = canvas.getCanvasView();
        options.appendTo = canvas.getAdderEl();
        options.prefix = editor.getConfig().stylePrefix;
        options.posFetcher = canvasView.getElementPos.bind(canvasView);
        options.mousePosFetcher = canvas.getMouseRelativePos;

        // Create the adder for the canvas if not yet created
        if (!canvasAdder || opt.forceNew) {
            this.canvasAdder = editor.Utils.Adder.init(editor, options);
            canvasAdder = this.canvasAdder;
        }

        canvasAdder.setOptions(options);
        canvasAdder.blur();
        canvasAdder.focus(el);
        return canvasAdder;
    },
    stop() {
        const adder = this.canvasAdder;
        adder && adder.blur();
    }
}