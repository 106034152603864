export const
    cmdImport = 'gjs-open-import-webpage',
    cmdDeviceDesktop = 'set-device-desktop',
    cmdDeviceTablet = 'set-device-tablet',
    cmdDeviceMobile = 'set-device-mobile',
    cmdClear = 'canvas-clear',
    cmdHelp = 'help',
    cmdAssets = 'left-assets',
    cmdSettings = 'left-settings',
    cmdPublish = 'publish',
    cmdPageSeo = 'page-seo',
    cmdZoomIn = 'zoom-in',
    cmdZoomOut = 'zoom-out',
    cmdShare = 'share',
    blcBuilder = 'block-builder',
    blcShape = 'shape-divider',
    btnBasic = 'button-basic',
    icon = 'icon',
    animate = 'animate',
    shapeIcon = `<svg viewBox="0 0 22 22" fill="currentColor"><path d="M2 2v18h18V2H2zm0-2h18a2 2 0 012 2v18a2 2 0 01-2 2H2a2 2 0 01-2-2V2C0 .9.9 0 2 0zM1 13L2 0h18l1 9-20 4z"></path></svg>`,
    typeAnimation = 'animations-select',
    typeTransform = 'transform',
    typeEase = 'cubic-bezier',
    animations = [{
        label: 'Attention seekers',
        options: [{
            value: 'bounce'
        },
        {
            value: 'flash'
        },
        {
            value: 'pulse'
        },
        {
            value: 'rubberBand'
        },
        {
            value: 'shakeX'
        },
        {
            value: 'shakeY'
        },
        {
            value: 'headShake'
        },
        {
            value: 'swing'
        },
        {
            value: 'tada'
        },
        {
            value: 'wobble'
        },
        {
            value: 'jello'
        },
        {
            value: 'heartBeat'
        },
        ]
    },
    {
        label: 'Back entrances',
        options: [{
            value: 'backInDown'
        },
        {
            value: 'backInLeft'
        },
        {
            value: 'backInRight'
        },
        {
            value: 'backInUp'
        }
        ]
    },
    {
        label: 'Back exits',
        options: [{
            value: 'backOutDown'
        },
        {
            value: 'backOutLeft'
        },
        {
            value: 'backOutRight'
        },
        {
            value: 'backOutUp'
        }
        ]
    },
    {
        label: 'Bouncing entrances',
        options: [{
            value: 'bounceIn'
        },
        {
            value: 'bounceInDown'
        },
        {
            value: 'bounceInLeft'
        },
        {
            value: 'bounceInRight'
        },
        {
            value: 'bounceInUp'
        }
        ]
    },
    {
        label: 'Bouncing exits',
        options: [{
            value: 'bounceOut'
        },
        {
            value: 'bounceOutDown'
        },
        {
            value: 'bounceOutLeft'
        },
        {
            value: 'bounceOutRight'
        },
        {
            value: 'bounceOutUp'
        }
        ]
    },
    {
        label: 'Fading entrances',
        options: [{
            value: 'fadeIn'
        },
        {
            value: 'fadeInDown'
        },
        {
            value: 'fadeInDownBig'
        },
        {
            value: 'fadeInLeft'
        },
        {
            value: 'fadeInLeftBig'
        },
        {
            value: 'fadeInRight'
        },
        {
            value: 'fadeInRightBig'
        },
        {
            value: 'fadeInUp'
        },
        {
            value: 'fadeInUpBig'
        },
        {
            value: 'fadeInTopLeft'
        },
        {
            value: 'fadeInTopRight'
        },
        {
            value: 'fadeInBottomLeft'
        },
        {
            value: 'fadeInBottomRight'
        }
        ]
    },
    {
        label: 'Fading exits',
        options: [{
            value: 'fadeOut'
        },
        {
            value: 'fadeOutDown'
        },
        {
            value: 'fadeOutDownBig'
        },
        {
            value: 'fadeOutLeft'
        },
        {
            value: 'fadeOutLeftBig'
        },
        {
            value: 'fadeOutRight'
        },
        {
            value: 'fadeOutRightBig'
        },
        {
            value: 'fadeOutUp'
        },
        {
            value: 'fadeOutUpBig'
        },
        {
            value: 'fadeOutTopLeft'
        },
        {
            value: 'fadeOutTopRight'
        },
        {
            value: 'fadeOutBottomLeft'
        },
        {
            value: 'fadeOutBottomRight'
        }
        ]
    },
    {
        label: 'Flippers',
        options: [{
            value: 'flip'
        },
        {
            value: 'flipInX'
        },
        {
            value: 'flipInY'
        },
        {
            value: 'flipOutX'
        },
        {
            value: 'flipOutY'
        }
        ]
    },
    {
        label: 'Lightspeed',
        options: [{
            value: 'lightSpeedInRight'
        },
        {
            value: 'lightSpeedInLeft'
        },
        {
            value: 'lightSpeedOutRight'
        },
        {
            value: 'lightSpeedOutLeft'
        }
        ]
    },
    {
        label: 'Rotating entrances',
        options: [{
            value: 'rotateIn'
        },
        {
            value: 'rotateInDownLeft'
        },
        {
            value: 'rotateInDownRight'
        },
        {
            value: 'rotateInUpLeft'
        },
        {
            value: 'rotateInUpRight'
        }
        ]
    },
    {
        label: 'Rotating exits',
        options: [{
            value: 'rotateOut'
        },
        {
            value: 'rotateOutDownLeft'
        },
        {
            value: 'rotateOutDownRight'
        },
        {
            value: 'rotateOutUpLeft'
        },
        {
            value: 'rotateOutUpRight'
        }
        ]
    },
    {
        label: 'Specials',
        options: [{
            value: 'hinge'
        },
        {
            value: 'jacInTheBox'
        },
        {
            value: 'rollIn'
        },
        {
            value: 'rollOut'
        }
        ]
    },
    {
        label: 'Zooming entrances',
        options: [{
            value: 'zoomIn'
        },
        {
            value: 'zoomInDown'
        },
        {
            value: 'zoomInLeft'
        },
        {
            value: 'zoomInRight'
        },
        {
            value: 'zoomInUp'
        }
        ]
    },
    {
        label: 'Zooming exits',
        options: [{
            value: 'zoomOut'
        },
        {
            value: 'zoomOutDown'
        },
        {
            value: 'zoomOutLeft'
        },
        {
            value: 'zoomOutRight'
        },
        {
            value: 'zoomOutUp'
        }
        ]
    },
    {
        label: 'Sliding entrances',
        options: [{
            value: 'slideInDown'
        },
        {
            value: 'slideInLeft'
        },
        {
            value: 'slideInRight'
        },
        {
            value: 'slideInUp'
        },
        ]
    },
    {
        label: 'Sliding exits',
        options: [{
            value: 'slideOutDown'
        },
        {
            value: 'slideOutLeft'
        },
        {
            value: 'slideOutRight'
        },
        {
            value: 'slideOutUp'
        }
        ]
    }
    ],
    timingFunctions = ['ease', 'linear', 'ease-in', 'ease-out', 'ease-in-out'];