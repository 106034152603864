import BaseAdder from './BaseAdder';
import blocksManager from "../panels/blocks";

const createHandler = (name, { prefix }) => {
    const pfx = prefix || '';
    const el = document.createElement('i');
    el.className = `${pfx}adder-h ${pfx}adder-h-${name}`;
    el.innerHTML = '<svg viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg>';
    el.setAttribute(`data-${pfx}handler`, name);
    return el;
}

const createPlaceholder = (name, { prefix }) => {
    const pfx = prefix || '';
    const el = document.createElement('div');
    const int = document.createElement('div');
    el.className = `${pfx}placeholder ${name}`;
    int.className = `${pfx}placeholder-int`;
    el.appendChild(int);
    return el;
}

/**
 * Init adder on the element if possible
 * @param {HTMLElement|Component} elem
 * @param {Object} editor
 */
export const initAdder = (elem, editor) => {
    const pfx = editor.getConfig('stylePrefix') || '';
    const adderClass = `${pfx}adder`;
    const model = elem || editor.getSelected();
    const addable = model && model.get('addable');
    let options = {};

    const toggleBodyClass = (method, e, opts) => {
        const docs = opts.docs;
        docs &&
            docs.forEach(doc => {
                const body = doc.body;
                const cls = body.className || '';
                body.className = (method === 'add' ? `${cls} ${adderClass}` : cls.replace(adderClass, '')).trim();
            });
    }

    if (editor && addable) {
        const el = model.getEl();
        if (typeof addable === 'object') {
            options = { ...options, ...addable, parent: options }
        }
        editor.runCommand('adder', { el, options, force: 1 });
    } else {
        editor.stopCommand('adder');
    }
}

class Adder extends BaseAdder {

    setup() {
        const { opts } = this;
        const pfx = opts.prefix || '';
        const appendTo = opts.appendTo || document.body;
        let container = this.container;

        // Create container if none
        if (!container) {
            container = document.createElement('div');
            container.className = `${pfx}adder-c`;
            appendTo.appendChild(container);
            this.container = container;
        }

        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }

        // Create
        const handlers = {};
        ['t', 'r', 'b', 'l', 'c'].forEach(
            hdl => (handlers[hdl] = opts[hdl] ? createHandler(hdl, opts) : '')
        );

        for (let n in handlers) {
            const handler = handlers[n];
            handler && container.appendChild(handler);
        }

        this.handlers = handlers;
        this.updateTarget = opts.updateTarget;
        this.posFetcher = opts.posFetcher;
        this.onClick = opts.onClick;
        this.onUpdateContainer = opts.onUpdateContainer;
    }

    /**
     * Start adding blocks
     * @param {Event} e
     */
    start({ target }) {
        this.getBlocksPos.bind(this);
        this.getElementPos.bind(this);
        target = this.selectedHandler || target;
        const { editor, container, el, opts } = this;
        const hdlrName = target.getAttribute('data-gjs-handler');
        const index = hdlrName === 'c' ? 0 :
            (hdlrName === 't' || hdlrName === 'l' ? 0 : 1);
        const blocks = index === 0 ? opts.blocks.append : opts.blocks.add;
        const $el = this.$blocks || blocksManager(editor, {
            append: hdlrName === 'c',
            blocks,
            index,
            onClose: () => {
                this.$placeholder?.remove();
            }
        }).template;
        // Place blocks el
        const { top, left } = this.getBlocksPos(target, hdlrName);
        $el.css({
            display: 'flex',
            top,
            left
        });
        // Open custom blocks
        container.appendChild($el.get(0));
        this.$blocks = $el;
        this.$placeholder?.remove();
        let placeholder;
        const appendAfter = hdlrName !== 'c' ? el :
            (el.lastChild ? el.lastChild : el);
        const basePos = this.getElementPos(appendAfter);
        switch (hdlrName) {
            case 'c':
            case 'b':
            case 't':
                placeholder = createPlaceholder('horizontal', opts);
                if (hdlrName === 'c' && !el.lastChild) {
                    basePos.top = basePos.top + 5
                    basePos.left = basePos.left + 3;
                    basePos.width = basePos.width - 8;
                } else if (hdlrName === 'c' || index === 1) {
                    basePos.top = basePos.top + basePos.height;
                }
                basePos.height = 'auto';
                break;
            case 'r':
            case 'l':
                placeholder = createPlaceholder('vertical', opts);
                if (index === 1) {
                    basePos.left = basePos.left + basePos.width;
                }
                basePos.width = 'auto';
                break;
            default:
                placeholder = createPlaceholder('horizontal', opts);
                break;
        }
        this.$placeholder = editor.$(placeholder);
        this.$placeholder.css({
            display: 'block',
            ...basePos
        });
        editor.Canvas.getGlobalToolsEl().appendChild(this.$placeholder.get(0));
    }

    /**
     * Handle click
     * @param {Event} e
     */
    handleClick(e) {
        const el = e.target;
        if (this.isHandler(el)) {
            if (this.selectedHandler !== el && this.$blocks) {
                this.$blocks.remove();
                this.$blocks = null;
            }
            this.selectedHandler = el;
            this.start(e);
            if (typeof this.onClick === 'function') this.onClick(e);
        } else if (el !== this.el) {
            this.selectedHandler = '';
            this.blur();
        }
    }
}

export default {
    init(editor, opts) {
        return new Adder(editor, opts);
    }
}