export default (editor, opts = {}) => {
    const { $ } = editor;
    const bm = editor.Blocks;
    const all = bm.getAll();
    const pfx = editor.getConfig().stylePrefix;
    const template = $(`<div id="${pfx}pn-blocks" class="${pfx}pn-float ${pfx}one-bg"><div class="${pfx}popup-heading"><span>Place Blocks</span><span class="close">x</span></div><div class="${pfx}popup-body"></div></div>`);
    const templateBody = template.find(`.${pfx}popup-body`);
    const close = e => {
        template.css('display', 'none');
        opts?.onClose();
    }
    template.find('.close').on('click', close);
    const { blocks } = opts;
    const filtered = all.filter(block => {
        if (blocks === '*') return true;
        if (opts.invert) return !(blocks.indexOf(block.get('id')) > -1 ||
            blocks.indexOf(block.get('label')) > -1);
        return blocks.indexOf(block.get('id')) > -1 ||
            blocks.indexOf(block.get('label')) > -1;
    });
    templateBody.append(bm.render(filtered, {
        external: true,
        ignoreCategories: true,
        appendOnClick(block, editor) {
            opts.beforeAppend && opts.beforeAppend();
            const { index } = opts;
            const sel = editor.getSelected();
            if (sel) {
                const at = sel.index() + index;
                if (!opts.append) sel.parent()?.append(block.get('content'), { at });
                else sel.append(block.get('content'));
                template.css('display', 'none');
                opts.onClose && opts.onClose();
            } else {
                notyf.open({
                    type: 'warning',
                    message: 'Can\'t select component, element may be dynamically generated'
                });
            }
        }
    }));
    return { template, close };
}