export default (editor, opts = {}) => {
    const options = {
        ...{
            // default options
            // Custom button element which triggers Cloudinary modal
            btnEl: '',

            // Text for the button in case the custom one is not provided
            btnText: 'Upload images',

            // Cloudinary's widget options
            cloudinaryOpts: {},

            // On complete upload callback
            // asset - Assets object, eg. {url:'...', filename: 'name.jpeg', ...}
            // for debug: console.log(asset);
            onComplete(asset) {
            },

            // Handle events
            onEvent(ev) {
            },

            // On failed upload callback
            // asset - Assets object, eg. {url:'...', filename: 'name.jpeg', ...}
            // for debug: console.log(asset);
            onFailed(asset) {
            },

            // On widget not found
            onWidgetNotFound() { },
        },
        ...opts
    };

    let btnEl;
    const pfx = editor.getConfig('stylePrefix');
    const { $ } = editor;
    const { cloudinaryOpts } = options;
    let cloudinaryWidget;
    if (!cloudinaryWidget && window.cloudinary) {
        cloudinaryWidget = window.cloudinary.createUploadWidget({
            cloudName: 'htmlpage',
            uploadPreset: 'htmlpage_images',
            sources: [
                'local',
                'url',
                'camera',
                'image_search',
                'google_drive',
                'facebook',
                'dropbox',
                'instagram',
                'shutterstock',
                'getty',
                'istock',
                'unsplash'
            ],
            showAdvancedOptions: true,
            cropping: false,
            multiple: true,
            maxFiles: 8,
            resourceType: 'image',
            maxImageFileSize: 2000000,
            defaultSource: 'local',
            styles: {
                palette: {
                    window: '#262327',
                    sourceBg: '#1c1a1d',
                    windowBorder: '#ffffff4d',
                    tabIcon: '#936a9b',
                    inactiveTabIcon: '#8e9fbf',
                    menuIcons: '#b5a5b9',
                    link: '#936a9b',
                    action: '#73308d',
                    inProgress: '#00ffcc',
                    complete: '#33ff00',
                    error: '#cc3333',
                    textDark: '#000000',
                    textLight: '#ffffff'
                },
                frame: {
                    background: '#1d151e99'
                },
                fonts: {
                    default: null,
                    'sans-serif': {
                        url: null,
                        active: true,
                    }
                }
            },
            ...cloudinaryOpts
        },
            (err, info) => {
                if (!err) {
                    const { event } = info;
                    if (event === 'success') {
                        addAsset(info.info);
                        options.onComplete(info.info);
                    }
                    else {
                        options.onEvent(info);
                    }
                } else {
                    options.onFailed(err);
                }
            }
        );
    }

    // Add command to get widget
    editor.Commands.add('cloudinary-widget', () => cloudinaryWidget);

    // When the Asset Manager modal is opened
    editor.on('run:open-assets', () => {
        const modal = editor.Modal;
        const modalBody = modal.getContentEl();
        const uploader = modalBody.querySelector(`.${pfx}am-file-uploader`);
        const assetsHeader = modalBody.querySelector(`.${pfx}am-assets-header`);
        const assetsBody = modalBody.querySelector(`.${pfx}am-assets-cont`);

        uploader && (uploader.style.display = 'none');
        assetsBody.style.width = '100%';

        const showUploadWidget = () => {
            cloudinaryWidget && cloudinaryWidget.open();
            !cloudinaryWidget && options.onWidgetNotFound();
        }

        // Instance button if not yet exists
        if (!btnEl) {
            btnEl = options.btnEl ? $(options.btnEl) : $(`<button class="${pfx}btn-prim ${pfx}btn-cloudinary">
                    ${options.btnText}
                </button>`);
            btnEl.on('click', showUploadWidget);
        }

        assetsHeader.appendChild(btnEl.get(0));
    });

    /**
     * Add new assets to the editor
     * @param {Object} file
     */
    const addAsset = (file) => {
        return editor.AssetManager.add({
            id: file.asset_id,
            name: file.original_filename,
            size: file.bytes,
            src: file.secure_url
        });
    };
};