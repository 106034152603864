import domtoimage from "./toImage";
import { saveAs } from "file-saver";
import JSZip from "jszip";

/**
 *
 * @param {String} unitValue ex: 1in
 * @param {Object} [options]
 * @param {String} [options.outputType='px']
 * @param {Number} [options.ppi=96]
 * @returns
 */
export function convertUnit(
  input,
  { outputUnit = "px", ppi = 96, precision, returnNumber = false } = {}
) {
  const unitToInchMap = { px: ppi, in: 1, mm: 25.4, cm: 2.54 };

  let inputValue = input;
  let inputUnit = "px";

  // Parse input value and unit from string
  if (typeof input === "string") {
    const inputValueStr = take(input, input.length - 2).join("");
    inputValue = parseFloat(inputValueStr);
    if (isNaN(inputValue)) return undefined;

    inputUnit = takeRight(input, 2).join("");
  }

  let outputValue;

  //Return the input value if input unit and output unit are the same
  if (inputUnit === outputUnit) {
    outputValue = inputValue;
  } else {
    // convert incoming unit into inches to get pixels
    const valueInInches = inputValue / unitToInchMap[inputUnit];
    outputValue = valueInInches * unitToInchMap[outputUnit];
  }

  // Apply precision
  if (precision !== undefined && isInteger(precision))
    outputValue = round(outputValue, precision);

  return returnNumber ? outputValue : `${outputValue}${outputUnit}`;
}

export function getViewportDimensions({
  format,
  isLandscape,
  width,
  height,
  ppi = 96,
}) {
  width = convertUnit(width, { returnNumber: true });
  height = convertUnit(height, { returnNumber: true });

  let result = [1080, 1080];
  let dimensions = [width, height];

  const formatDimensionMap = {
    Mobile: ["11.25in", "11.25in"], // Square
    Tablet: ["7.5in", "11.25in"], // Film
    DeskTop: ["11.25in", "20in"], // Story
  };

  if (format) dimensions = formatDimensionMap[format];

  const conversionOptions = { ppi, precision: 0 };

  if (dimensions && dimensions.length === 2)
    result = [
      convertUnit(dimensions[0], conversionOptions),
      convertUnit(dimensions[1], conversionOptions),
    ];
  if (isLandscape) result = _.reverse(result);

  return { width: result[0], height: result[1] };
}

/**
 * Sets the Editor device
 * @param {GJSEditor} editor editor instance
 * @param {Object} options template options
 * @returns
 */
export function setDevice(editor, options = {}) {
  if (!editor) return;
  const {
    format = "Desktop",
    isPortrait = false,
    width,
    height,
    margin,
  } = options;

  let deviceName = `${format}${!isPortrait ? "" : " Portrait"}`;
  const deviceId = deviceName.toLowerCase().replace(" ", "-");

  const device = editor.DeviceManager.get(deviceId);
  let minHeight = device?.attributes?.height;

  if (!format) {
    deviceName = `c-${Date.now()}`;
    const deviceWidth = !isPortrait ? height : width;
    editor.DeviceManager.add(deviceName, deviceWidth, { withMedia: false });
    minHeight = !isPortrait ? width : height;
  }

  editor.setDevice(deviceId);

  if (!minHeight) return;

  minHeight = `calc(${minHeight} - ${margin?.top} - ${margin?.bottom})`;

  const wrapper = editor.getWrapper();

  if (wrapper?.view?.el) wrapper.view.el.style.minHeight = minHeight;
}

export function deselectComponents(editor) {
  if (!editor) return;
  const um = editor.UndoManager;
  um.stop();
  editor.select();
  um.start();
}

export const setZoom = (editor, value = 100) => {
  if (!editor) return;
  const min = 20;
  const max = 300;

  if (value < min) value = min;
  if (value > max) value = max;

  const cv = editor.Canvas;

  cv.setZoom(value);

  const canvasEl = cv.getElement();
  const framesWrapperEl = canvasEl.querySelector(".htmlpage-cv-canvas__frames");
  const percent = value / 100;

  framesWrapperEl.style.width = `calc(100% / ${percent})`;
  framesWrapperEl.style.height = `calc(100% / ${percent})`;

  const xCoord = (canvasEl.offsetWidth - framesWrapperEl.offsetWidth) / 2;
  const yCoord = (canvasEl.offsetHeight - framesWrapperEl.offsetHeight) / 2;

  cv.setCoords(xCoord, yCoord);
  return value;
};

export const zoomIn = (editor, amt = 10) => {
  if (!editor || typeof amt !== "number") return;
  const currentZoom = editor.Canvas.getZoom();
  return setZoom(editor, currentZoom + amt);
};

export const zoomOut = (editor, amt = 10) => {
  if (!editor || typeof amt !== "number") return;
  const currentZoom = editor.Canvas.getZoom();
  return setZoom(editor, currentZoom - amt);
};

export function setEditorDimension(editor) {
  if (!editor || !editor.Canvas) return;
  const frame = editor.Canvas.getFrameEl();
  const body = editor.Canvas.getBody();
  if (!frame || !body) return;
  const document = body.querySelector(".document");
  if (!document) return;
  const documentHeight = document.offsetHeight;
  frame.style.height = `${documentHeight}px`;
  editor.refresh();
}

export async function getImage(node, options = {}, clb, clbErr) {
  try {
    const blob = await domtoimage.toBlob(node, {
      options,
      ...{
        cacheBust: true,
        style: {
          "background-color": "white",
          ...editor.getWrapper().getStyle(),
        },
      },
    });
    clb && clb(blob);
  } catch (err) {
    clbErr && clbErr(err);
  }
}

export function handleDownloadImage(filename, blob) {
  saveAs(blob, filename);
}

export async function returnImage(node, options = {}) {
  try {
    const blob = await domtoimage.toBlob(node, {
      ...options,
      ...{
        cacheBust: true,
        style: {
          "background-color": "white",
          ...editor.getWrapper().getStyle(),
          overflow: "hidden",
          width: options.width + "px",
          height: options.height + "px",
        },
      },
    });
    return blob;
  } catch (err) {
    return "";
  }
}

export async function getZippedFiles(editor, state) {
  const zip = new JSZip();
  const node = editor.Canvas.getBody();
  const frame = editor.Canvas.getFrameEl();

  const w = frame.style.width;
  const h = frame.style.height;

  frame.style.transition = "none";
  frame.style.width = "720px";
  frame.style.height = "720px";
  const squarePng =
    state.square && state.png && state.best
      ? { "square.png": await returnImage(node, { width: 720, height: 720 }) }
      : {};
  const squareJpg =
    state.square && state.jpeg && state.best
      ? {
          "square.jpg": await returnImage(node, {
            width: 720,
            height: 720,
            type: "image/jpg",
          }),
        }
      : {};
  const squarePngLow =
    state.square && state.png && state.low
      ? {
          "square_low.png": await returnImage(node, {
            width: 720,
            height: 720,
          }),
        }
      : {};
  const squareJpgLow =
    state.square && state.jpeg && state.low
      ? {
          "square_low.jpg": await returnImage(node, {
            width: 720,
            height: 720,
            type: "image/jpg",
          }),
        }
      : {};

  frame.style.width = "960px";
  frame.style.height = "720px";
  const printPng =
    state.print && state.png && state.best
      ? { "print.png": await returnImage(node, { width: 960, height: 720 }) }
      : {};
  const printJpg =
    state.print && state.jpeg && state.best
      ? {
          "print.jpg": await returnImage(node, {
            width: 960,
            height: 720,
            type: "image/jpg",
          }),
        }
      : {};
  const printPngLow =
    state.print && state.png && state.low
      ? {
          "print_low.png": await returnImage(node, { width: 960, height: 720 }),
        }
      : {};
  const printJpgLow =
    state.print && state.jpeg && state.low
      ? {
          "print_low.jpg": await returnImage(node, {
            width: 960,
            height: 720,
            type: "image/jpg",
          }),
        }
      : {};

  frame.style.width = "1280px";
  frame.style.height = "720px";
  const storiesPng =
    state.stories && state.png && state.best
      ? { "stories.png": await returnImage(node, { width: 1280, height: 720 }) }
      : {};
  const storiesJpg =
    state.stories && state.jpeg && state.best
      ? {
          "stories.jpg": await returnImage(node, {
            width: 1280,
            height: 720,
            type: "image/jpg",
          }),
        }
      : {};
  const storiesPngLow =
    state.stories && state.png && state.low
      ? {
          "stories_low.png": await returnImage(node, {
            width: 1280,
            height: 720,
          }),
        }
      : {};
  const storiesJpgLow =
    state.stories && state.jpeg && state.low
      ? {
          "stories_low.jpg": await returnImage(node, {
            width: 1280,
            height: 720,
            type: "image/jpg",
          }),
        }
      : {};

  const images = {
    ...squarePng,
    ...squareJpg,
    ...printPng,
    ...printJpg,
    ...storiesPng,
    ...storiesJpg,
    ...squarePngLow,
    ...squareJpgLow,
    ...printPngLow,
    ...printJpgLow,
    ...storiesPngLow,
    ...storiesJpgLow,
  };

  Object.keys(images).forEach((key) => {
    zip.file(key, images[key]);
  });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    // see FileSaver.js
    saveAs(content, `mujo_images_${Date.now()}.zip`);
  });

  frame.style.width = w;
  frame.style.height = h;
  frame.style.transition = "";
}

export default {
  convertUnit,
  getViewportDimensions,
  setDevice,
  deselectComponents,
  setZoom,
  zoomIn,
  zoomOut,
  setEditorDimension,
  getImage,
  handleDownloadImage,
};
