export const type = "mujo-row";

export default (dc, { editor }) => {
  const def = {
    model: {
      defaults: {
        name: "Columns",
        // icon: '<i class="gjs-badge__icon fa-kit fa-columns-1-1-1-1"></i>',
        selectable: false,
        hoverable: false,
        draggable: false, // this can be DRAGGED INTO THESE components
        // layerable: false,
      },
      init() {
        const components = this.components();
        const classes = components.models[0].get("classesList");
        const cols = components.length;
        if (classes && cols >= Object.keys(classes).length)
          this.set({ droppable: false });
        else this.set({ droppable: true });
        this.on(
          "component:update:components",
          (component, components, _update) => {
            const cols = components.length;
            const classes = component.get("classesList");
            components.each((c) => {
              classes[cols] && c.setClass(classes[cols]);
            });
            if (classes && cols >= Object.keys(classes).length) {
              this.set({ droppable: false });
            } else this.set({ droppable: true });
          }
        );
      },
    },
  };

  const model = {
    defaults: {
      name: "Row",
      tagName: "section",
      attributes: {
        "data-mujo-category": "layout",
        class: "text-gray-600 body-font min-h-screen",
      },
      draggable: `[data-gjs-type="wrapper"], [data-gjs-type="mujo-column"]`,
      droppable: false,
      components: [
        {
          type: "mujo-columns-container",
          attributes: {
            "data-mujo-category": "layout",
            class: "px-5 mx-auto min-h-screen",
          },
          components: [
            {
              attributes: {
                "data-mujo-category": "layout",
                class: "flex flex-wrap min-h-screen",
              },
              type: "mujo-columns",
              components: [
                {
                  type: "mujo-column",
                },
                {
                  type: "mujo-column",
                },
                {
                  type: "mujo-column",
                },
              ],
            },
          ],
        },
      ],
      unstylable: [
        "height",
        "width",
        "min-width",
        "max-width",
        "max-height",
        "min-height",
        "margin",
      ],
    },
    init() {
      // this.on("change:components", this.updateColumnStyles);
      // this.on("component:remove:before", this.cleanColumnStyles);
    },
  };

  // defaults.icon = '<i class="gjs-badge__icon fa-kit fa-row"></i>';

  dc.addType("mujo-columns", def);
  dc.addType("mujo-columns-container", {
    extend: "mujo-columns",
    model: { defaults: { droppable: false } },
    init() {},
  });
  dc.addType(type, { model });
};
