import { getZippedFiles } from "../utils";
import { cmdShare } from "../consts";

export class Share {
  constructor(editor, opts = {}) {
    this.editor = editor;
    this.$ = editor.$;
    this.pfx = editor.getConfig("stylePrefix");
    this.opts = opts;
    this.isSubVisible = false;
    this.state = {
      fileType: "images",
      square: true,
      print: false,
      stories: false,
      jpeg: false,
      png: true,
      best: false,
      low: true,
    };
  }

  setState(state) {
    this.state = { ...this.state, ...state };
  }

  render() {
    const { $ } = this;
    const $el = $(this.getTemplate());
    this.getEvents($el);
    this.$el = $el;
    return $el;
  }

  async onDownload() {
    const { state, editor, $el, isSubVisible } = this;
    editor.stopCommand("sw-visibility");
    await getZippedFiles(editor, state);
    const sub = $el.find(".share-sub-menu");
    isSubVisible && sub && sub.hide();
    this.isSubVisible = false;
    editor.stopCommand(cmdShare);
  }

  onShare() {}

  onDuplicate() {}

  onRename() {}

  getEvents($el) {
    const { $ } = this;

    // While open submenu add class
    $el.find(".btn")?.on("click", (e) => {
      const $target = $(e.target);
      const { isSubVisible } = this;
      const sub = $el.find(".share-sub-menu");
      if ($target.hasClass("download")) {
        isSubVisible && sub && sub.hide();
        !isSubVisible && sub && sub.show();
        this.isSubVisible = !isSubVisible;
      } else if ($target.hasClass("btn")) {
        isSubVisible && sub && sub.hide();
        this.isSubVisible = false;
      }
    });

    $el.find("input[type=checkbox")?.on("change", (e) => {
      this.onCheckboxChanged(e);
    });

    $el.find(".htmlpage-btn")?.on("click", () => {
      this.onDownload();
    });
  }

  onCheckboxChanged(ev) {
    this.state[ev.target.id] = ev.target.checked;
  }

  getCheckbox(label, checked, id, disabled) {
    return `<div class="htmlpage-trt-trait htmlpage-trt-trait--checkbox" style="justify-content: space-between;">
      <div class="htmlpage-label-wrp__" data-label=""><div class="htmlpage-label" title="${label}">${label}</div></div>
      <div class="htmlpage-field-wrp htmlpage-field-wrp--checkbox" data-input="" style="flex-grow: 0;">
        <label class="htmlpage-field htmlpage-field-checkbox" data-input="" style="border: 1px solid;"><input id="${id}" type="checkbox" placeholder="placeholder" ${
      checked ? "checked" : ""
    } ${disabled ? "disabled" : ""}>
        <i class="htmlpage-chk-icon"></i>
        </label>
      </div>
    </div>`;
  }

  getSelect() {
    return `<div><select style="width: 100%; padding: 10px; background-color:rgba(0,0,0,0.25); border: 1px solid; color: inherit; border-radius: 5px; margin-top: 5px;"><option value="Images">Images</option></select></div>`;
  }

  getTemplate() {
    const { state } = this;
    return `<div class="share-menu" style="display: none;">
      <div class="btn download">Download
        <div class="share-sub-menu" style="display: none;">
          <div class="section">File type
            ${this.getSelect()}
          </div>
          <div class="section">File Format:
            ${
              this.getCheckbox("1 x 1", state.square, "square") +
              this.getCheckbox("3 x 4", state.print, "print") +
              this.getCheckbox("9 x 16", state.stories, "stories")
            }
          </div>
          <div class="section">Download as:
            ${
              this.getCheckbox("JPEG", state.jpeg, "jpeg") +
              this.getCheckbox("PNG", state.png, "png")
            }
          </div>
          <div class="section">Quality
            ${
              this.getCheckbox("Best (300DPI)", state.best, "best", true) +
              this.getCheckbox("Low (72DPI)", state.low, "low")
            }
          </div>
          <div class="htmlpage-btn htmlpage-btn-prim">Download</div>
        </div>
      </div>
      <div class="btn">Share</div>
      <div class="btn">Duplicate</div>
      <div class="btn">Rename</div>
    </div>`;
  }
}
