import { animate, blcBuilder, blcShape, btnBasic, shapeIcon, icon } from '../consts';

export default (editor, opts = {}) => {
    const bm = editor.Blocks;
    const {
        linkBlock,
        quoteBlock,
        textBlock,
        btnBlock,
        bldBlock,
        hrBlock,
        heroBlock,
        teamBlock,
        shapedividerBlock,
        sectionBlock,
        spanBlock,
        footerBlock,
        headerBlock,
        iconBlock,
        animateBlock
    } = opts;
    const crd = `<div data-gjs-name="Card" class="card"><img alt="Jane" style="width:100%;height:150px"/><div class="container"><h2>Jane Doe</h2><p class="title">CEO &amp; Founder</p><p>Some text that describes me lorem ipsum ipsum lorem.</p><p>example@example.com</p><p><button class="button">Contact</button></p></div></div>`
    const col = `<div data-gjs-name="Cell" class="column">${crd}</div>`
    const crdStyles = `.card {margin: auto;max-width: 280px;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);}.container {padding: 16px;}.title {color: grey;}.button {border: none;outline: 0;display: inline-block;padding: 8px;text-align: center;cursor: pointer;width: 100%;}`;

    linkBlock && bm.add('link-block', {
        category: 'Basic',
        label: 'Link Block',
        attributes: { class: 'fa fa-link' },
        content: {
            type: 'link',
            editable: false,
            droppable: true,
            style: {
                display: 'inline-block',
                padding: '5px',
                'min-height': '50px',
                'min-width': '50px'
            }
        },
        ...linkBlock
    });

    quoteBlock && bm.add('quote', {
        label: 'Quote',
        category: 'Basic',
        attributes: { class: 'fa fa-quote-right' },
        content: `<blockquote class="quote">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit</blockquote>`,
        ...quoteBlock
    });

    textBlock && bm.add('text-basic', {
        category: 'Basic',
        label: 'Text section',
        attributes: { class: 'fa fa-align-left' },
        content: `<section class="bdg-sect"><h1 class="heading">Insert title here</h1><p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p></section>`,
        ...textBlock
    });

    sectionBlock && bm.add('section-block', {
        category: 'Basic',
        label: 'Section',
        media: `<svg width="50" viewBox="0 0 16 16"><path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2zm0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14z"/></svg>`,
        content: {
            tagName: 'section',
            attributes: { class: 'sect' },
            components: [{
                name: 'section',
                tagName: 'div',
                attributes: { class: 'sect-cont' }
            },
                `<style>.sect {padding: 50px 0 50px 0;}.sect-cont {margin: 0 50px 0 50px;min-height: 50px;}</style>`
            ]
        },
        ...sectionBlock
    });

    spanBlock && bm.add('span-block', {
        category: 'Basic',
        label: 'Span',
        attributes: { class: 'fa fa-arrows-h' },
        content: `<span class="spn"></span><style>.spn {padding: 10px;width: 100px;height: 100px;}</style>`,
        ...spanBlock
    });

    btnBlock && bm.add(btnBasic, {
        category: 'Basic',
        label: 'Button',
        attributes: { class: 'fa fa-square-o' },
        content: { type: btnBasic },
        ...btnBlock
    });

    bldBlock && bm.add(blcBuilder, {
        category: 'Basic',
        label: 'Tag',
        attributes: { class: 'fa fa-square-o' },
        content: {
            type: blcBuilder,
            editable: false,
            droppable: true,
            style: {
                'min-height': '50px',
                'padding': '10px',
            }
        },
        ...bldBlock
    });

    hrBlock && bm.add('line-divider', {
        category: 'Basic',
        label: 'Line divider',
        attributes: { class: 'fa fa-arrows-v' },
        content: `<hr class="solid"><style>.solid {border-top: 3px solid #bbb;}</style>`,
        ...hrBlock
    });

    heroBlock && bm.add('hero-block', {
        category: 'Extra',
        label: 'Hero',
        attributes: { class: 'fa fa-address-card-o' },
        content: `<div class="hero-text"><h1>Insert headline here</h1><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p><button>Let's Go</button></div><style>.hero-text {width: 300px;margin: auto;text-align: center;}</style>`,
        ...heroBlock
    });

    teamBlock && bm.add('team', {
        category: 'Extra',
        label: 'Team',
        attributes: { class: 'fa fa-address-card-o' },
        content: `<div class="row">${col + col + col}</div><style>.row {display: flex;justify-content: flex-start;align-items: stretch;flex-wrap: wrap;padding: 10px;}.column {flex-grow: 1;padding: 8px;min-width: 290px;}${crdStyles}</style>`,
        ...teamBlock
    });

    teamBlock && bm.add('team-card', {
        category: 'Extra',
        label: 'Team Card',
        attributes: { class: 'fa fa-address-card-o' },
        content: `${crd}<style>${crdStyles}</style>`,
        ...teamBlock
    });

    footerBlock && bm.add('footer', {
        category: 'Extra',
        label: 'Footer',
        attributes: { class: 'fa fa-anchor' },
        content: `<footer class="footer"><p>© Copyright Year</p><p><a href="#" class="footer-link">Terms of service</a><a href="#" class="footer-link">Privacy policy</a><a href="#" class="footer-link">About</a></p></footer><style>.footer {display: flex;justify-content: space-between;padding: 50px 25px;}.footer-link {text-decoration: none;margin-right: 10px;}</style>`,
        ...footerBlock
    });

    shapedividerBlock && bm.add(blcShape, {
        label: 'Shape Divider',
        media: shapeIcon,
        category: 'Extra',
        content: { type: blcShape },
        ...shapedividerBlock
    });

    headerBlock && bm.add("header", {
        label: 'Header',
        category: 'Basic',
        attributes: { class: 'fa fa-header' },
        content: {
            type: 'header',
            content: 'Insert text here',
            activeOnRender: 1,
        },
        ...headerBlock
    });

    iconBlock && bm.add(icon, {
        label: 'Icon',
        category: 'Basic',
        attributes: { class: 'fa fa-diamond' },
        content: { type: icon },
        ...iconBlock
    });

    animateBlock && bm.add(animate, {
        label: 'Scroll Animate',
        category: 'Extra',
        attributes: { class: 'fa fa-play' },
        content: { type: animate },
        ...animateBlock
    });

    bm.add('mujo-row', {
        label: 'Mujo Section',
        category: 'Mujo Editor',
        attributes: { class: 'fa fa-diamond' },
        content: { type: 'mujo-row' },
    })

    bm.add('mujo-column', {
        label: 'Mujo Column',
        category: 'Mujo Editor',
        attributes: { class: 'fa fa-diamond' },
        content: { type: 'mujo-column' },
    })
}