export const flexSect = {
    name: 'Flex',
    open: false,
    buildProps: ['justify-content', 'align-items', 'order', 'align-self'],
    properties: [
        {
            name: 'Flex flow',
            property: 'flex-flow',
            type: 'composite',
            requires: {
                display: ['flex']
            },
            properties: [
                {
                    name: 'Direction',
                    property: 'flex-direction',
                    type: 'radio',
                    defaults: 'row',
                    options: [
                        {
                            value: 'row',
                            name: 'Row',
                            className: 'icons-flex icon-dir-row',
                            title: 'Row',
                        },
                        {
                            value: 'row-reverse',
                            name: 'Row reverse',
                            className: 'icons-flex icon-dir-row-rev',
                            title: 'Row reverse',
                        },
                        {
                            value: 'column',
                            name: 'Column',
                            title: 'Column',
                            className: 'icons-flex icon-dir-col',
                        },
                        {
                            value: 'column-reverse',
                            name: 'Column reverse',
                            title: 'Column reverse',
                            className: 'icons-flex icon-dir-col-rev',
                        }
                    ],
                },
                {
                    name: 'Wrap',
                    property: 'flex-wrap',
                    type: 'radio',
                    defaults: 'nowrap',
                    options: [
                        {
                            value: 'nowrap',
                            name: 'none',
                            title: 'nowrap'
                        },
                        {
                            value: 'wrap',
                            name: 'wrap'
                        },
                        {
                            value: 'wrap-reverse',
                            name: 'reverse',
                            title: 'wrap-reverse'
                        }
                    ]
                }
            ]
        },
        {
            name: 'Justify',
            property: 'justify-content',
            type: 'radio',
            defaults: 'flex-start',
            options: [
                {
                    value: 'flex-start',
                    className: 'icons-flex icon-just-start',
                    title: 'Start',
                },
                {
                    value: 'flex-end',
                    title: 'End',
                    className: 'icons-flex icon-just-end',
                },
                {
                    value: 'space-between',
                    title: 'Space between',
                    className: 'icons-flex icon-just-sp-bet',
                },
                {
                    value: 'space-around',
                    title: 'Space around',
                    className: 'icons-flex icon-just-sp-ar',
                },
                {
                    value: 'center',
                    title: 'Center',
                    className: 'icons-flex icon-just-sp-cent',
                }
            ],
        },
        {
            name: 'Align items',
            property: 'align-items',
            type: 'radio',
            defaults: 'center',
            options: [
                {
                    value: 'flex-start',
                    title: 'Start',
                    className: 'icons-flex icon-al-start',
                },
                {
                    value: 'flex-end',
                    title: 'End',
                    className: 'icons-flex icon-al-end',
                },
                {
                    value: 'stretch',
                    title: 'Stretch',
                    className: 'icons-flex icon-al-str',
                },
                {
                    value: 'center',
                    title: 'Center',
                    className: 'icons-flex icon-al-center',
                }
            ]
        },
        {
            name: 'Order',
            property: 'order',
            type: 'slider',
            defaults: 0,
            min: 0
        },
        {
            name: 'Flex',
            property: 'flex',
            type: 'composite',
            requiresParent: {
                display: ['flex']
            },
            properties: [
                {
                    name: 'Grow',
                    property: 'flex-grow',
                    type: 'integer',
                    defaults: 0,
                    min: 0
                },
                {
                    name: 'Shrink',
                    property: 'flex-shrink',
                    type: 'integer',
                    defaults: 0,
                    min: 0
                },
                {
                    name: 'Basis',
                    property: 'flex-basis',
                    type: 'slider',
                    units: ['px', '%', 'vw', ''],
                    unit: '',
                    defaults: 'auto',
                }
            ],
        },
        {
            name: 'Align self',
            property: 'align-self',
            type: 'radio',
            defaults: 'auto',
            options: [
                {
                    value: 'auto',
                    name: 'Auto',
                },
                {
                    value: 'flex-start',
                    title: 'Start',
                    className: 'icons-flex icon-al-start',
                },
                {
                    value: 'flex-end',
                    title: 'End',
                    className: 'icons-flex icon-al-end',
                },
                {
                    value: 'stretch',
                    title: 'Stretch',
                    className: 'icons-flex icon-al-str',
                },
                {
                    value: 'center',
                    title: 'Center',
                    className: 'icons-flex icon-al-center',
                }
            ],
        }
    ]
}