import BaseAdder from './BaseAdder';
import blocksManager from "../panels/blocks";

const createDynamicHandler = (name, hName, { prefix }) => {
    const pfx = prefix || '';
    const el = document.createElement('div');
    el.className = `${pfx}adder-d-c`;
    el.setAttribute(`data-${pfx}handler`, hName);

    const h = document.createElement('i');
    h.className = `${pfx}adder-h ${pfx}adder-d-h ${pfx}adder-h-${name}`;
    h.innerHTML = '<svg viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></svg>';
    h.setAttribute(`data-${pfx}handler`, hName);

    const pl = document.createElement('div');
    const int = document.createElement('div');
    pl.className = `${pfx}placeholder horizontal`;
    int.className = `${pfx}placeholder-int`;
    int.setAttribute(`data-${pfx}handler`, hName);
    pl.appendChild(int);

    el.appendChild(pl);
    el.appendChild(h);
    return { el, pl, h };
}

// Generic event abstractor
const addEvent = (obj, evt, fn) => {
    if (typeof obj.addEventListener !== 'undefined') {
        obj.addEventListener(evt, fn, false);
    } else if (typeof obj.attachEvent !== 'undefined') {
        obj.attachEvent(`on${evt}`, fn);
    }
}

/**
 * Attach hover event to element
 * @param {HTMLElement} elem
 * @param {Function} clb
 * @param {number} delay
 */
const onHover = (elem, clb, delay = 1000) => {
    let hoverTimer;
    addEvent(elem, 'mouseover', e => {
        hoverTimer = setTimeout(() => clb(e), delay);
        e.target.classList.add('hovered');
    });
    addEvent(elem, 'mouseout', e => {
        clearTimeout(hoverTimer);
        e.target.classList.remove('hovered');
    });
}

class AddWidget extends BaseAdder {
    setup() {
        const { opts } = this;
        const pfx = opts.prefix || '';
        const appendTo = opts.appendTo || document.body;

        // Create container if none
        if (!this.container) {
            this.container = document.createElement('div');
            this.container.className = `${pfx}adder-d-b`;
        }
        appendTo.appendChild(this.container);
        this.updateTarget = opts.updateTarget;
        this.posFetcher = opts.posFetcher;
        this.onClick = opts.onClick;
        this.onUpdateContainer = opts.onUpdateContainer;
        this.unhoverable = [];
        this.disable();
    }

    /**
     * Setup hover events for element
     * @param {HTMLElement} el
     */
    addHover(el) {
        onHover(el, this.handleHover.bind(this), this.opts.hoverDelay);
    }

    /**
     * Setup hover events for element
     * @param {HTMLElement} el
     */
    ignoreHover(el) {
        this.unhoverable.push(el);
    }

    /**
     * Remove hover events for element
     * @param {HTMLElement} el
     */
    removeEl(el) {
        const { unhoverable } = this;
        const i = unhoverable.indexOf(el);
        const l = unhoverable.length - 1;
        if (i > -1) {
            if (i !== l)
                [unhoverable[i], unhoverable[l]] = [unhoverable[l], unhoverable[i]];
            unhoverable.pop();
        }
        if (el === this.hoveredEl) {
            this.resetHandlers();
        } else {
            this.editor.$(el).children().each((i, child) => {
                if (child === this.hoveredEl) {
                    this.resetHandlers();
                }
            });
        }
    }

    /**
     * Reset handlers
     */
    resetHandlers() {
        const { $ } = this.editor;
        if (this.hdlrTop) {
            $(this.hdlrTop.el).css({
                top: -25
            });
            $(this.hdlrBottom.el).css({
                top: -25
            });
        }
        this.hoveredEl = null;
    }

    deselect() {
        if (this.hdlrTop) {
            this.hdlrTop.el.style.display = 'none';
            this.hdlrBottom.el.style.display = 'none';
        }
        if (this.canDeselect) {
            this.blur();
            this.blocksObj?.close();
        }
    }

    /**
     * Start adding blocks
     * @param {Event} e
     */
    start(target) {
        this.getBlocksPos.bind(this);
        this.getElementPos.bind(this);
        this.ogTarget = target
        target = this.selectedHandler || target;
        const { editor, opts } = this;
        const hdlrName = target.getAttribute('data-gjs-handler');
        const index = hdlrName === 't' ? 0 : 1;
        this.holder && this.holder.remove();
        if (!this.ogTarget.classList.contains('mini')) {
            const sel = editor.getSelected();
            const at = sel?.index() + index || 0;
            this.holder = sel?.parent()?.append({ type: 'holder' }, { at })[0];
        }
        this.ogTarget.classList.add('focused');
        this.blocksObj?.template.remove();
        this.blocksObj = blocksManager(editor, {
            invert: this.ogTarget.classList.contains('mini'),
            blocks: opts.blocks.add,
            index,
            beforeAppend: () => {
                this.holder && this.holder.remove();
            },
            onClose: () => {
                this.holder && this.holder.remove();
                this.ogTarget.classList.remove('focused');
                this.blocksOpen = false;
            }
        });
        const $el = this.$blocks || this.blocksObj.template;
        // Place blocks el
        this.hdlrTop.el.style.display = '';
        this.hdlrBottom.el.style.display = '';
        const { top, left } = this.getBlocksPos(target, hdlrName);
        $el.css({
            display: 'flex',
            top,
            left
        });
        // Open custom blocks
        this.container.appendChild($el.get(0));
        this.$blocks = $el;
        this.canDeselect = true;
        this.blocksOpen = true;
    }

    /**
     * Handle hover
     * @param {Event} e
     */
    handleHover({ target }) {
        if (!this.blocksOpen && !this.unhoverable.includes(target) && !this.disabled) {
            this.getElementPos.bind(this);
            const { editor, globalTools, opts } = this;
            const { left, top, width, height } = this.getElementPos(target);
            const offset = target.ownerDocument.defaultView.pageYOffset + 10;
            if (!this.hdlrTop || !this.hdlrBottom) {
                this.hdlrTop = createDynamicHandler('d', 't', opts);
                this.hdlrBottom = createDynamicHandler('d', 'b', opts);
                editor.$([this.hdlrTop.el, this.hdlrBottom.el])
                    .on('click', this.handleClick.bind(this));
                onHover(this.hdlrTop.el, () => { });
                onHover(this.hdlrBottom.el, () => { });
            }
            this.hdlrTop.el.style.display = '';
            this.hdlrBottom.el.style.display = '';
            this.hdlrTop.el.classList.remove('focused');
            this.hdlrBottom.el.classList.remove('focused');
            if (target.parentElement.getAttribute('data-gjs-type') === 'wrapper') {
                this.hdlrTop.el.classList.remove('mini');
                this.hdlrBottom.el.classList.remove('mini');
            } else {
                this.hdlrTop.el.classList.add('mini');
                this.hdlrBottom.el.classList.add('mini');
            }
            editor.$(this.hdlrTop.el).css({
                height: 20,
                top: top - offset,
                left: left,
                width
            });
            editor.$(this.hdlrBottom.el).css({
                height: 20,
                top: top + height - offset,
                left: left,
                width
            });
            globalTools.appendChild(this.hdlrTop.el);
            globalTools.appendChild(this.hdlrBottom.el);
            this.hoveredEl = target;
        }
    }

    /**
     * Handle click
     * @param {Event} e
     */
    handleClick({ target }) {
        const { opts } = this;
        const pfx = opts.prefix;
        if (!this.blocksOpen) {
            this.canDeselect = false;
            this.blur();
            this.focus(this.hoveredEl);
            this.selectedHandler = target.querySelector(`.${pfx}adder-h.${pfx}adder-d-h`);
            this.editor.select(this.hoveredEl);
            this.start(target);
            if (typeof this.onClick === 'function') this.onClick(e);
        } else {
            this.opts.ifBlocksOpen(this.editor);
        }
    }
}

export default {
    init(editor, opts) {
        return new AddWidget(editor, opts);
    }
}