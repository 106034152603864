import { attrsToString } from "../utils/helpers";

/**
 * Build toggle add mode botton
 * 
 * @param {Object} $ Cashdom object
 * @param {String} pfx Style prefix
 */
export default (editor, $, pfx) => {
    const attrs = {
        class: `fa fa-plus ${pfx}styles-btn`,
        style: 'display:none',
        'data-tooltip-pos': 'left',
        'data-tooltip': 'Toggle Add Mode'
    }
    const toggleAdd = $(`<div ${attrsToString(attrs)}></div>`);
    toggleAdd.on('click', e => {
        if (editor.Utils.DynamicAdder.disabled) {
            editor.Utils.DynamicAdder._enable();
            notyf.success('Add mode on');
        } else {
            editor.Utils.DynamicAdder.deselect();
            editor.Utils.DynamicAdder._disable();
            notyf.success('Add mode off');
        }
    });

    return toggleAdd;
}