const defaultOpts = {
    blocks: {
        add: '*', // Blocks that can be added outside the selected block
        append: '*' // Blocks that can be added inside the selected block
    },
    // Indicates custom target updating stratergy
    updateTarget: null,
    // Function which gets HTMLElement as an arg and returns its relative position
    ratioDefault: 0,
    onClick: null,
    posFetcher: null,
    onUpdateContainer: () => { },
    // Handlers
    t: 1, // Top
    r: 1, // Right
    b: 1, // Bottom
    l: 1, // Left
    c: 1 // Center
}

const getBoundingRect = (el, win) => {
    const w = win || window;
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + w.pageXOffset,
        top: rect.top + w.pageYOffset,
        width: rect.width,
        height: rect.height
    }
}

export default class BaseAdder {
    /**
     * Init the adder
     * @param {Object} editor
     * @param {Object} options
     */
    constructor(editor, opts = {}) {
        this.editor = editor;
        this.globalTools = editor.Canvas.getGlobalToolsEl();
        this.setOptions(opts);
        // * SECTION: Events
        return this;
    }

    /**
     * Get current configuration
     * @returns {Object}
     */
    get config() {
        return this.opts
    }

    /**
     * Setup options
     * @param {Object} options
     */
    setOptions(options = {}) {
        this.opts = { ...defaultOpts, ...options };
        this.setup();
    }

    setup() { }

    _enable() {
        this._disabled = false;
        this.enable();
    }

    _disable() {
        this._disabled = true;
        this.disable();
    }

    /**
     * Enable/Show icons
     */
    enable() {
        if (!this._disabled) {
            this.disabled = false;
            this.container.style.display = '';
            this.globalTools.querySelectorAll(`.${this.opts.prefix}adder-d-c`).forEach(el => {
                el.style.display = '';
            });
        }
    }

    /**
     * Disable/Hide icons
     */
    disable() {
        this.disabled = true;
        this.container.style.display = 'none';
        this.globalTools.querySelectorAll(`.${this.opts.prefix}adder-d-c`).forEach(el => {
            el.style.display = 'none';
        });
    }

    /**
     * Start handling main event
     * @param {Event} e
     */
    start(e) { }

    /**
     * Detects if passed element is an add handler
     * @param {HTMLElement} el
     * @returns {Boolean}
     */
    isHandler(el) {
        const { handlers } = this;
        for (let n in handlers) {
            if (handlers[n] === el) return true;
        }
        return false;
    }

    /**
     * Returns the focused element
     * @returns {HTMLElement}
     */
    get focusedEl() {
        return this.el;
    }

    /**
     * Returns the parent of the focused element
     * @returns {HTMLElement}
     */
    get parentEl() {
        return this.el.parentElement;
    }

    /**
     * Returns documents 
     */
    get documentEl() {
        return [this.el.ownerDocument, document];
    }

    /**
     * Returns element position
     * @param {HTMLElement} el
     * @param {Object} opts custom options
     * @returns {Object}
     */
    getElementPos(el, opt = {}) {
        const { posFetcher } = this;
        return posFetcher ? posFetcher(el, opt) : getBoundingRect(el);
    }

    /**
     * Focus adder on the element, attaches handlers to it 
     * @param {HTMLElement} el
     */
    focus(el) {
        // Avoid refocusing
        if (el && el === this.el) return;
        this.el = el;
        this.updateContainer({ forceShow: 1 });
        // handle on click
        this.editor.$(this.container)
            .find(`.${this.opts.prefix}adder-h`)
            .on('click', this.handleClick.bind(this));
    }

    /**
     * Blur from element
     */
    blur() {
        this.container.style.display = 'none';

        if (this.el) {
            // remove on click
            this.editor.$(this.container)
                .find(`.${this.opts.prefix}adder-h`)
                .off('click');
            this.el = null;
        }
        if (this.$blocks) {
            this.$blocks.remove();
            this.$blocks = null;
        }
        if (this.$placeholder) {
            this.$placeholder.remove();
            this.$placeholder = null;
        }
    }

    /**
     * Calculate the position of block manager
     * @param {HTMLElement} target
     * @param {string} hdlrName
     */
    getBlocksPos(target, hdlrName) {
        // Get top left -> use handlers to center -> place top or bottom based on space
        const elPos = this.getElementPos(target);
        const canvasPos = this.editor.Canvas.getRect();
        if (!canvasPos) return {
            top: 0,
            left: 0
        };

        const spaceToRight = canvasPos.width - elPos.left - elPos.width;
        const spaceToLeft = canvasPos.width - spaceToRight;
        const spaceToBottom = canvasPos.height - elPos.top - elPos.height;
        const spaceToTop = canvasPos.height - spaceToBottom;
        const elWidth = 240;
        const elHeight = 400;
        const offset = 5;
        const hdlrDim = 20 + offset;
        const overlapV = (spaceToTop + hdlrDim + elHeight) - canvasPos.height;
        const overlapH = (spaceToLeft + hdlrDim + elWidth) - canvasPos.width;

        switch (hdlrName) {
            case 'c':
            case 'b':
            case 't':
                elPos.left = elPos.left - 110;
                if (spaceToBottom > spaceToTop && spaceToBottom > elHeight) elPos.top = elPos.top + hdlrDim;
                else if (spaceToTop > spaceToBottom && spaceToTop > elHeight) elPos.top = elPos.top - elHeight - offset;
                else if (spaceToBottom > spaceToTop && spaceToBottom < elHeight) elPos.top = elPos.top + hdlrDim - overlapV;
                else if (spaceToTop > spaceToBottom && spaceToTop < elHeight) elPos.top = elPos.top - elHeight - offset + overlapV;

                if (canvasPos.left > elPos.left) elPos.left += canvasPos.left - elPos.left;
                else if (elPos.left + elWidth > canvasPos.width) elPos.left -= elPos.left + elWidth - canvasPos.left - canvasPos.width;
                break
            case 'l':
            case 'r':
                elPos.top = elPos.top - 140;
                if (spaceToRight > spaceToLeft && spaceToRight > elWidth) elPos.left = elPos.left + hdlrDim;
                else if (spaceToLeft > spaceToRight && spaceToLeft > elWidth) elPos.left = elPos.left - elWidth - offset;
                else if (spaceToRight > spaceToLeft && spaceToRight < elWidth) elPos.left = elPos.left + hdlrDim - overlapH;
                else if (spaceToLeft > spaceToRight && spaceToLeft < elWidth) elPos.left = elPos.left - elWidth - offset + overlapH;

                if (canvasPos.top > elPos.top) elPos.top += canvasPos.top - elPos.top;
                else if (elPos.top + elHeight > canvasPos.height) elPos.top -= elPos.top + elHeight - canvasPos.top - canvasPos.height;
                break;
            default:
                break
        }

        return {
            top: elPos.top,
            left: elPos.left
        }
    }

    updateContainer(opt = {}) {
        const { opts, container, el } = this;
        const { style } = container;

        if (!opts.avoidContainerUpdate && el) {
            if (opt.forceShow) style.display = 'block';
        }

        this.onUpdateContainer({
            el: container,
            adder: this,
            opts: {
                ...opts,
                ...opt
            }
        })
    }

    /**
     * Get selected handler name
     * @return {string}
     */
    get selectedHandlerName() {
        const { handlers } = this;
        if (!this.selectedHandler) return;
        for (let n in handlers) {
            if (handlers[n] === this.selectedHandler) return n;
        }
    }

    /**
     * Handle click
     * @param {Event} e
     */
    handleClick(e) { }
}