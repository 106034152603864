const properties = [
    {
        name: 'title',
        label: 'Title',
        placeholder: 'eg. title',
        type: 'textbox',
        full: true
    },
    {
        name: 'description',
        label: 'Description',
        placeholder: 'eg. description',
        type: 'textarea',
        full: true
    },
    {
        name: 'favicon',
        label: 'Favicon',
        placeholder: 'eg. https://img.com/favicon.ico',
        type: 'image',
        full: true
    },
    {
        name: 'ogImage',
        label: 'OG:image',
        placeholder: 'eg. https://img.com/og-image.jpg',
        type: 'image',
        full: true
    },
    {
        name: 'ogUrl',
        label: 'OG:URL',
        placeholder: 'eg. https://your.site',
        type: 'textbox'
    },
    {
        name: 'keywords',
        label: 'Keywords',
        placeholder: 'eg. website builder, drag and drop',
        type: 'textbox'
    },
    {
        name: 'author',
        label: 'Author',
        placeholder: 'eg. HTMLPAGE',
        type: 'textbox'
    },
    {
        name: 'note',
        label: 'Note',
        placeholder: 'eg. placeholder',
        type: 'textbox'
    },
    {
        name: 'customHead',
        label: 'Head',
        type: 'button'
    },
    {
        name: 'globalCss',
        label: 'CSS',
        type: 'button'
    }
];

const font = [
    {
        name: 'fontTitle',
        label: 'Font Style',
        placeholder: 'eg. Roboto, sans-serif',
        type: 'textbox'
    },
    {
        name: 'fontLink',
        label: 'Font Link',
        placeholder: 'eg. <link href=...',
        type: 'textbox'
    },
    {
        name: 'fontSubmit',
        label: 'Add Font',
        type: 'button'
    }
];

const feature = [
    {
        name: 'featTitle',
        label: 'Title',
        placeholder: 'eg. title',
        type: 'textbox',
        full: true,
    },
    {
        name: 'featDescription',
        label: 'Description',
        placeholder: 'eg. description',
        type: 'textarea',
        full: true,
    },
    {
        name: 'featSubmit',
        label: 'Submit',
        type: 'button'
    }
];

export class Settings {
    constructor(editor, opts = { panelId: 'views-container' }) {
        this.editor = editor;
        this.$ = editor.$;
        this.pfx = editor.getConfig('stylePrefix');
        this.opts = opts;
        this.panelViews = this.findWithinEditor(`.${this.pfx}pn-views-container`);
        this.isShowing = true;
        this.title = '';
        this.description = '';
        this.font = '';
        this.link = '';
    }

    findPanel() {
        const pn = this.editor.Panels;
        const id = this.opts.panelId;
        const panel = pn.getPanel(id) || pn.addPanel({ id });
        return panel;
    }

    findWithinEditor(selector) {
        return this.$(selector, this.editor.getEl());
    }

    buildInputs(properties) {
        const { $, pfx, editor } = this;
        const cont = $(`<div class="${pfx}sm-properties" style="display:block;"></div>`);

        $.each(properties, property => {
            const input = $(`<div id="${pfx + property.name}" class="${pfx}sm-property ${property.full ? `${pfx}sm-property--full` : ''}" style="display: block;"><div class="${pfx}sm-label"><span class="${pfx}sm-icon " title="">${property.type !== 'button' ? property.label : ''}</span></div><div class="${pfx}fields"><div class="${pfx}field ${property.type !== 'button' ? 'w100' : ''}"><span class="${pfx}input-holder">${this.mapType(property)}</span></div></div></div>`);
            const { name } = property;
            if (name.startsWith('feat')) {
                input.find('input')
                    .on('change', e => this.title = e.target.value);
                input.find('textarea')
                    .on('change', e => this.description = e.target.value);
                input.find('button')
                    .on('click', () => this.submitRequest());
            } else if (name.startsWith('font')) {
                input.find('input')
                    .on('change', e => this.font = e.target.value);
                input.find('textarea')
                    .on('change', e => this.link = e.target.value);
                input.find('button')
                    .on('click', () => this.addFont());
            } else if (property.type === 'image') {
                const $preview = input.find(`.${pfx}sm-preview-file-cnt`);
                $preview.css({ 'background-image': `url("${editor.Pages.getSelected().get(name) || ''}")` });
                const $input = input.find('input')
                    .on('change', e => this.onUrlChange(name, e.target, $preview));
                input.find('button')
                    .on('click', () => this.openAssets(name, $input, $preview));
            } else {
                input.find('input, textarea')
                    .on('change', e => this.onChange(name, e.target));
                input.find('button')
                    .on('click', () => this.onClick(name));
            }
            cont.append(input);
        });

        return cont;
    }

    resetInputs() {
        const { $, editor, inputs } = this;
        inputs.find('input, textarea')
            .each((i, input) => {
                $(input).val(editor.Pages.getSelected().get(input.getAttribute('data-property')) || '')
            });
    }

    onChange(name, el) {
        const { editor } = this;
        const sel = editor.Pages.getSelected();
        const url = name === 'favicon' || name === 'ogImage' || name === 'ogUrl';
        const obj = {
            [name]: el.value
        };
        if (url) (checkUrl(value) && sel.set(obj)) ||
            (el.value = '');
        else sel.set(obj);
    }

    onUrlChange(name, el, $preview) {
        const { editor } = this;
        const sel = editor.Pages.getSelected();
        $preview.css('background-image', `url("${el.value}")`);
        sel.set({
            [name]: el.value
        });
    }

    onClick(name) {
        this.editor.runCommand(`settings-${name}`);
    }

    openAssets(name, $input, $preview) {
        const { editor } = this;
        editor.runCommand('open-assets', {
            target: null,
            types: ['image'],
            accept: 'image/*',
            onSelect: (model) => {
                const sel = editor.Pages.getSelected();
                const src = model.get('src');
                $input.val(src);
                $preview.css('background-image', `url("${src}")`);
                sel.set({ [name]: src });
                editor.Modal.close();
                editor.Assets.setTarget(null);
            }
        });
    }

    mapType(property) {
        const { pfx } = this;
        switch (property.type) {
            case 'textarea':
                return `<textarea data-property="${property.name}" placeholder="${property.placeholder}"></textarea>`;
            case 'button':
                return `<button data-property="${property.name}" class="${pfx}btn-prim">${property.label}</button>`;
            case 'image':
                return `<div class="${pfx}sm-preview-file"><div class="${pfx}sm-preview-file-cnt" style="background-image: url('/block-composer.svg');"></div></div>
                <div class="${pfx}sm-btn-c"><button class="${pfx}sm-btn ${pfx}btn-prim" type="button">Images</button></div><div style="clear:both;"></div>
                <input data-property="${property.name}" type="url" placeholder="${property.placeholder}">`;
            default:
                return `<input data-property="${property.name}" type="text" placeholder="${property.placeholder}">`;
        }
    }

    buildSettingsPanel() {
        const { $, editor, pfx } = this;
        const panel = this.findPanel();
        this.settingsPanel = $(`<div class="${pfx}sm-sector"><div class="${pfx}pn-content-header">页面设置</div><div class="${pfx}sm-property ${pfx}sm-composite"><div class="${pfx}sm-label ${pfx}four-color"><span class="${pfx}sm-icon " title="">Site</span></div><div class="${pfx}fields"><div class="${pfx}sm-field ${pfx}sm-composite"><span id="settings-input-holder"><!-- Inputs --></span></div></div></div><div class="${pfx}sm-property ${pfx}sm-composite"><div class="${pfx}sm-label ${pfx}four-color"><span class="${pfx}sm-icon " title="">Fonts</span></div><div class="${pfx}fields"><div class="${pfx}sm-field ${pfx}sm-composite"><span id="font-input-holder"><!-- Inputs --></span></div></div></div><div class="${pfx}sm-property ${pfx}sm-composite"><div class="${pfx}sm-label ${pfx}four-color"><span class="${pfx}sm-icon " title="">Report</span></div><div class="${pfx}fields"><div class="${pfx}sm-field ${pfx}sm-composite"><span id="feat-input-holder"><!-- Inputs --></span></div></div></div></div>`);
        this.settingsPanel.addClass('settings-panel');

        const inputHolder = this.settingsPanel.find('#settings-input-holder');
        const fontHolder = this.settingsPanel.find('#font-input-holder');
        const featHolder = this.settingsPanel.find('#feat-input-holder');
        this.inputs = this.buildInputs(properties);
        this.fontInputs = this.buildInputs(font);
        this.featInputs = this.buildInputs(feature);
        inputHolder.append(this.inputs);
        fontHolder.append(this.fontInputs);
        featHolder.append(this.featInputs);

        panel.set('appendContent', this.settingsPanel).trigger('change:appendContent');
    }

    showSettingsPanel() {
        this.isShowing = true;
        this.settingsPanel.css('display', 'block');
        this.resetInputs();
    }

    hideSettingsPanel() {
        if (this.settingsPanel) this.settingsPanel.css('display', 'none');
        this.isShowing = false;
    }

    addFont() {
        const { editor, $, link } = this;
        const doc = editor.Canvas.getDocument();
        const sel = editor.Pages.getSelected();
        if (link) {
            const linkEl = $(link).get(0);
            if (linkEl.nodeName === 'LINK') {
                linkEl.classList.add('font-link');
                doc.head.appendChild(linkEl);
                const fontLinks = sel.get('fontLinks');
                const store = fontLinks ? [...JSON.parse(fontLinks), link] : [link];
                sel.set({ fontLinks: JSON.stringify(store) });
                notyf.success("Link Added");
            }
        }
        if (this.font) {
            const font = { id: this.font, label: this.font.split(',')[0] };
            const prop = editor.StyleManager.getProperty('typography', 'font-family');
            prop.setOptions([...prop.getOptions(), font]);

            const fonts = sel.get('fonts');
            const store = fonts ? [...JSON.parse(fonts), font] : [font];
            sel.set({ fonts: JSON.stringify(store) });

            notyf.success("Font Added");
        }

        this.fontInputs.find('input').val('');
        this.fontInputs.find('textarea').val('');
        this.font = '';
        this.link = '';
    }

    submitRequest() {
        const obj = {
            'entry.348500254': this.title,
            'entry.267129761': this.description,
        };
        if (this.title && this.description) {
            const params = new URLSearchParams(obj);
            this.editor.Storage.get('remote').fetch(
                'https://docs.google.com/forms/d/e/' +
                '1FAIpQLSckTKRiaOh7NcbBuVIgjtffH63rr5-cPN6dXqmsYpDAYSPvPQ/formResponse?' +
                params.toString(),
                {
                    mode: 'no-cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            )
                .then(res => {
                    notyf.success("Submission recorded");
                })
                .catch(() =>
                    notyf.open({
                        type: 'warning',
                        message: 'Submission failed'
                    })
                );
        } else {
            notyf.open({
                type: 'warning',
                message: 'Some fields are missing'
            });
        }
        this.featInputs.find('input').val('');
        this.featInputs.find('textarea').val('');
        this.title = '';
        this.description = '';
    }
}

const checkUrl = (url) => {
    return /^(https?|ftp):\/\/[^\s/$.?#.[^\s*$@iS]/i.test(url);
}