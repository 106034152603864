/**
 * Close the open panel
 * 
 * @param {HTMLElement} model
 * 
 * @returns void
 */
export const close = model => {
    model.setAttribute('data-active', false);
}

/**
 * Close all panels
 * 
 * @param {NodeList} panels list of panels
 * 
 * @returns void
 */
export const closeAll = (panels) => {
    panels.each((idx, model) => model.setAttribute('data-active', false));
}

/**
 * Close all panels except given
 * 
 * @param {NodeList} panles list of panels
 * @param {HTMLElement} except
 * 
 * @returns void
 */
export const closeAllExcept = (panels, except) => {
    panels.each((idx, model) => {
        if (model !== except) {
            model.setAtrribute('data-active', false);
        }
    });
}

/**
 * Open given panel
 * 
 * @param {HTMLElement} model
 * 
 * @returns void
 */
export const open = model => {
    model.setAttribute('data-active', true);
}

/**
 * Append content to element
 * 
 * @param {HTMLElement} target Target element
 * @param {*} content String or HTMLElement to append to panel 
 * 
 * @returns void
 */
export const appendToContent = (target, content) => {
    if (content instanceof HTMLElement) {
        target.appendChild(content);
    } else if (content) {
        target.insertAdjacentHTML('beforeend', content);
    }
}

/**
 * Basic attributes object to string parser
 * 
 * @param {Array} attrs 
 * 
 * @returns string
 */
export const attrsToString = attrs => {
    const result = [];

    for (let key in attrs) {
        let value = attrs[key];
        const toParse = value instanceof Array || value instanceof Object;
        value = toParse ? JSON.stringify(value) : value;
        result.push(`${key}=${toParse ? `'${value}'` : `'${value}'`}`);
    }

    return result.length ? ` ${result.join(' ')}` : '';
};

/**
 * Drag element around
 * 
 * @param {HTMLElement} elmnt element you want draggable
 * @param {String} target used to select an element you want to act as a trigger for dragging, if left elmnt will be used
 * 
 *@returns void 
 */
export const dragElement = (elmnt, target = '') => {
    let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
    const h = target ? elmnt.querySelector(target) : 0;
    if (h) {
        // if present, the header is where you move the DIV from:
        h.addEventListener('mousedown', e => dragMouseDown(e));
    } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.addEventListener('mousedown', e => dragMouseDown(e));
    }

    const dragMouseDown = e => {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

    const elementDrag = e => {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    const closeDragElement = () => {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
    }
}

/**
 * Calculate viewport postion of popup
 * 
 * @param {Object} editor
 * @param {HTMLElment} popupEl 
 * @param {HTMLElement} target where popup is attached
 * @param {Object} opts
 * 
 * @returns Object 
 */
export const popupViewportPosition = (editor, popupEl, target, opts = {}) => {
    const canvasPos = editor.Canvas.getRect();
    if (!canvasPos) return;
    const pos = editor.Canvas.getElementPos(target);
    const popupHeight = popupEl.offsetHeight || 547;
    const popupWidth = popupEl.offsetWidth || 260;
    const offsetTop = opts.offsetTop || 0;
    const offsetRight = opts.offsetRight || 0;

    const spaceToRight = canvasPos.width - pos.left - pos.width;
    const spaceToBottom = canvasPos.height - pos.top - pos.height;

    const leftPos = pos.left > spaceToRight && pos.left > popupWidth ? pos.left - popupWidth :
        (spaceToRight > popupWidth ? pos.left + pos.width :
            canvasPos.width + canvasPos.left - popupWidth - offsetRight);
    const topPos = spaceToBottom > popupHeight - pos.height ? pos.top + canvasPos.top + offsetTop :
        canvasPos.height + canvasPos.top - popupHeight;

    return {
        top: topPos,
        left: leftPos
    }
}