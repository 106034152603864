import openImport from './openImport';
import openHelp from './openHelp';
import openClear from './openClear';
import customHead from './customHead';
import adder from './adder';
import { Assets } from '../panels/assets';
import { Settings } from '../panels/settings';
import {
    cmdImport,
    cmdDeviceDesktop,
    cmdDeviceTablet,
    cmdDeviceMobile,
    cmdClear,
    cmdHelp,
    cmdAssets,
    cmdSettings,
    cmdPublish,
    cmdPageSeo,
    cmdZoomIn,
    cmdZoomOut,
    cmdShare,
} from './../consts';
import { setDevice, setZoom, zoomIn, zoomOut, getImage, handleDownloadImage } from '../utils';

export default (editor, opts = {}) => {
    const cm = editor.Commands;

    let assetsPanel = null;
    let settingsPanel = null;

    cm.add(cmdAssets, {
        run: editor => {
            !assetsPanel && (assetsPanel = new Assets(editor)) && assetsPanel.buildAssetsPanel();
            assetsPanel.showAssetsPanel();
        },
        stop: editor => {
            assetsPanel && assetsPanel.hideAssetsPanel();
        },
    });
    cm.add(cmdSettings, {
        run: editor => {
            !settingsPanel && (settingsPanel = new Settings(editor)) && settingsPanel.buildSettingsPanel();
            settingsPanel.showSettingsPanel();
        },
        stop: editor => {
            settingsPanel && settingsPanel.hideSettingsPanel();
        },
    });
    cm.add(cmdPageSeo, customHead(editor, opts));

    const headOpts = {
        modalImportLabel: 'HTML(Not executed in editor)',
        modalImportContent: () => editor.Pages.getSelected().get('customHead') || '',
        modalImportButton: 'Save',
        importViewerOptions: {},
        modalImportTitle: 'Custom Header Content',
        onclick(e, val) {
            editor.Pages.getSelected().set({ customHead: val });
        },
    };
    const cssOpts = {
        modalImportLabel: 'CSS(Not executed in editor)',
        modalImportContent: () => editor.Pages.getSelected().get('globalCss') || '',
        modalImportButton: 'Save',
        importViewerOptions: { codeName: 'css' },
        modalImportTitle: 'Global CSS',
        onclick(e, val) {
            editor.Pages.getSelected().set({ globalCss: val });
        },
    };

    cm.add('adder', adder);
    cm.add(cmdImport, openImport(editor, opts));
    cm.add('settings-customHead', openImport(editor, headOpts));
    cm.add('settings-globalCss', openImport(editor, cssOpts));
    cm.add('get-current-name', ed => ed.Storage.getCurrentStorage().currentName);
    cm.add('open-fonts', () => window.open('https://htmlpage.cn', '_blank'));
    cm.add('clone-page', ed => {
        const sel = ed.Pages.getSelected();
        ed.Pages.add({
            name: (sel.get('name') || sel.id) + '-copy',
            component: sel.getMainComponent().clone()
        }, { select: true });
    });
    cm.add('add-block', ed => {
        notyf.info('Unavailable: Premium feature');
    });

    cm.add(cmdHelp, openHelp(editor, opts));
    cm.add(cmdPublish, ed => ed.runCommand('netlify-dashboard'));
    cm.add(cmdShare, { run: ed => ed.$('.share-menu')?.show(), stop: ed => ed.$('.share-menu')?.hide() });
    // Reset rulers, set rulers zoom
    cm.add(cmdDeviceDesktop, { run: ed => setDevice(ed, { format: 'Desktop' } ), stop() { } });
    cm.add(cmdDeviceTablet, { run: ed => setDevice(ed, { format: 'Tablet' }), stop() { } });
    cm.add(cmdDeviceMobile, { run: ed => setDevice(ed, { format: 'Square' }), stop() { } });
    cm.add(cmdZoomIn, ed => zoomIn(ed));
    cm.add(cmdZoomOut, ed => zoomOut(ed));
    cm.add(cmdClear, openClear(editor, opts));
    cm.add("save-image", ed => getImage(ed.Canvas.getBody(), {}, (blob) => handleDownloadImage("image__.png", blob)));
}