export default (editor, opts = {}) => {
    const pfx = editor.getConfig('stylePrefix');
    const modal = editor.Modal;
    const { $ } = editor;
    const importLabel = opts.modalHelpLabel;

    const mdlClass = `${pfx}mdl-dialog-sm`;
    const infoContainer = $(`<div id="info-panel">
        <img style="padding-top: 6px" src="block-composer.svg" height="20px" alt="logo">
        <br /><br />
        <div class="info-panel-label">
        <b>HTMLPAGE</b> 专业可视化网页构建器，支持企业专属定制。基于 <a class="info-panel-link ${pfx}four-color" target="_blank" href="https://htmlpage.cn">HTMLPAGE</a> 内核开发，集成简单，易扩展，支持市面上大部分流行的前端框架，包括Vue.js、React.js等。 点击<a class="info-panel-link ${pfx}four-color" target="_blank" href="/docs">集成说明文档</a> 了解更多信息。<br/><br/>扫描以下二维码咨询：<br/><div style="padding: 16px 0"><img width="200" height="200" src="https://coscdn.htmlpage.cn/2023/11/20231120140536734-300x300.jpg?imageMogr2/format/jpg/interlace/1"></div></div></div>`);

    return {
        run(editor) {
            const mdlDialog = $(`.${pfx}mdl-dialog`);
            mdlDialog.addClass(mdlClass);
            modal.setTitle(importLabel);
            modal.setContent($('#info-panel').length ? $('#info-panel') : infoContainer);
            modal.open();
            modal.getModel().once('change:open', () => {
                mdlDialog.removeClass(mdlClass);
                editor.stopCommand(this.id);
            });
        },

        stop() {
            modal.close();
        }
    }
}