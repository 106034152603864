export const type = "mujo-column";

export default (dc, options) => {
  const def = {
    model: {
      defaults: {
        name: "Container",
        // icon: '<i class="gjs-badge__icon fa-kit fa-columns-1-1-1-1"></i>',
        highlightable: false,
        selectable: false,
        hoverable: false,
        draggable: false, // this can be DRAGGED INTO THESE components
        // layerable: false,
      },
    },
  };

  const model = {
    defaults: {
      attributes: {
        "data-dm-category": "layout",
        class: "p-3 pb-1 md:w-1/3 h-fit",
      },
      name: "Column",
      // Classes to apply for the given number of columns. Determines maximum number of columns
      classesList: {
        1: "p-3 pb-1 md:w-full h-fit",
        2: "p-3 pb-1 md:w-1/2 h-fit",
        3: "p-3 pb-1 md:w-1/3 h-fit",
        4: "p-3 pb-1 md:w-1/4 sm:w-1/2 h-fit",
        5: "p-3 pb-1 md:w-1/3 sm:w-1/2 h-fit",
        6: "p-3 pb-1 md:w-1/3 sm:w-1/2 h-fit",
        7: "p-3 pb-1 md:w-1/4 sm:w-1/3 h-fit",
        8: "p-3 pb-1 md:w-1/4 sm:w-1/3 h-fit",
      },
      draggable: `[data-gjs-type="mujo-columns"]`,
      components: `<div data-gjs-type="mujo-container" class="bg-gray-100 bg-opacity-75 px-8 pt-4 md:pb-18 pb-4 rounded-lg overflow-hidden text-center relative"><h2 class="tracking-widest text-xs title-font text-gray-400 mb-1">CATEGORY</h2><h1 class="title-font text-sm md:text-xl font-medium text-gray-900 md:mb-3 mb-1">Raclette Blueberry Nextious Level</h1><p class="leading-relaxed md:text-sm text-xs md:mb-3 mb-1">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p><a class="text-indigo-500 text-sm inline-flex items-center">Learn More<svg viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 ml-2"><path d="M5 12h14"></path><path d="M12 5l7 7-7 7"></path></svg></a><div class="text-center leading-none flex justify-center py-2 mt-2"><span class="text-gray-400 mr-3 inline-flex items-center leading-none text-sm pr-3 py-1 border-r-2 border-gray-200"><svg stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" class="w-4 h-4 mr-1"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>1.2K</span><span class="text-gray-400 inline-flex items-center leading-none text-sm"><svg stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" class="w-4 h-4 mr-1"><path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path></svg>6</span></div></div>`,
    },
    init() {
      this.on(
        "component:update:components",
        (component, components, update) => {}
      );
    },
  };

  // defaults.icon = '<i class="gjs-badge__icon fa-kit fa-column"></i>';

  dc.addType("mujo-container", def);
  dc.addType(type, { model });
};
