import { animate, blcBuilder, btnBasic } from '../consts';
import shape from './shape';
import icon from './icon';
import mujo from './mujo';

export default (editor, opts = {}) => {
    const domc = editor.DomComponents;

    const id = {
        name: 'id',
    };

    const title = {
        name: 'title',
    }

    shape(editor, opts);
    icon(editor, {});

    domc.addType("header", {
        isComponent(el) {
            if (
                el &&
                el.tagName &&
                ["H1", "H2", "H3", "H4", "H5", "H6"].includes(el.tagName)
            ) {
                return { type: "header" };
            }
        },
        extend: 'text',
        model: {
            defaults: {
                "custom-name": "Header",
                icon: '<i class="fa fa-header"></i>',
                tagName: 'h1',
                traits: [
                    id,
                    title,
                    {
                        type: "select",
                        options: [
                            { value: "h1", name: 'One (largest)' },
                            { value: "h2", name: 'Two' },
                            { value: "h3", name: 'Three' },
                            { value: "h4", name: 'Four' },
                            { value: "h5", name: 'Five' },
                            { value: "h6", name: 'Six (smallest)' },
                        ],
                        label: "Size",
                        name: "tagName",
                        changeProp: 1,
                    },
                ],
            },
        },
        extendView: 'text',
        view: {}
    });

    domc.addType(animate, {
        model: {
            defaults: {
                icon: '<i class="fa fa-play"></i>',
                name: 'Animate',
                tagName: 'div',
                draggable: '[data-gjs-type=wrapper]',
                stylable: false,
                copyable: false,
                attributes: {
                    class: 'not-visible'
                },
                threshold: 0.5,
                delay: 0,
                'script-props': ['threshold', 'delay'],
                traits: [
                    id,
                    title,
                    {
                        type: "number",
                        label: "Threshold",
                        name: "threshold",
                        placeholder: '0',
                        step: 0.1,
                        min: 0,
                        max: 1,
                        changeProp: 1,
                    },
                    {
                        type: "number",
                        label: "Delay(ms)",
                        name: "delay",
                        placeholder: '0',
                        step: 1,
                        min: 0,
                        changeProp: 1,
                    },
                    {
                        type: "button",
                        text: "Reload Script",
                        full: true,
                        command: ed => ed.getSelected().trigger('change:script')
                    }
                ],
                content: `<svg width="70" height="70" fill="#87b2f6" style="display:block;margin:auto;" viewBox="0 0 16 16"><path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"/></svg><div style="text-align:center;color:#87b2f6">This is a placeholder and will not be rendered.Toggle visibility in layers</div>`,
                script({ threshold, delay }) {
                    const inViewPort = (entries, observer) => {
                        entries.forEach(({ target, isIntersecting }) => {
                            target.classList.toggle('not-visible', !isIntersecting);
                        });
                    }

                    const obsOpts = {
                        root: document,
                        threshold,
                        delay
                    };
                    const Obs = new IntersectionObserver(inViewPort, obsOpts);

                    const Els_inViewport = document.body.querySelectorAll('*');
                    Els_inViewport.forEach(El => {
                        Obs.observe(El);
                    });
                },
                styles: `.not-visible { animation: none !important }`
            },
            init() {
                editor.on('component:add', () => this.trigger('change:script'));
            },
            toHTML() {
                return `<noscript id="${this.getId()}"></noscript>`
            }
        }
    });

    domc.addType(blcBuilder, {
        model: {
            defaults: {
                icon: '<i class="fa fa-square-o"></i>',
                name: 'Tag',
                tagName: 'section',
                tag: 'main',
                components: {
                    tagName: 'main',
                    style: {
                        'min-height': '100px',
                        'min-width': '200px',
                    }
                },
                traits: [
                    id,
                    title,
                    {
                        label: 'Tag',
                        name: 'tag',
                        type: 'select',
                        options: [
                            { value: 'article' },
                            { value: 'aside' },
                            { value: 'article' },
                            { value: 'div' },
                            { value: 'footer' },
                            { value: 'header' },
                            { value: 'main' },
                            { value: 'nav' }
                        ],
                        changeProp: 1,
                    },
                ],
            },
            init() {
                this.on('change:tag', this.changeTag);
            },
            changeTag() {
                this.components().reset({
                    tagName: this.get('tag'),
                    style: {
                        'min-height': '100px',
                        'min-width': '200px',
                    }
                });
            }
        }
    });

    domc.addType(btnBasic, {
        isComponent: el => el.tagName == 'BUTTON',

        model: {
            defaults: {
                tagName: 'button',
                name: 'Button',
                icon: '<i class="fa fa-square"></i>',
                text: 'Action',
                traits: [
                    id,
                    title,
                    {
                        name: 'name',
                    },
                    {
                        name: 'text',
                        changeProp: true,
                    }
                ]
            },

            init() {
                const comps = this.components();
                const tChild = comps.length === 1 && comps.models[0];
                const chCnt = (tChild && tChild.is('textnode') && tChild.get('content')) || '';
                const text = chCnt || this.get('text');
                this.set({ text });
                this.on('change:text', this.__onTextChange);
                (text !== chCnt) && this.__onTextChange();
            },

            __onTextChange() {
                this.components(this.get('text'));
            },
        },

        view: {
            events: {
                click: e => e.preventDefault(),
            },
        },
    });

    domc.addType('holder', {
        model: {
            defaults: {
                icon: '<i class="fa fa-play"></i>',
                name: 'Placeholder',
                tagName: 'div',
                style: {
                    'pointer-events': 'none',
                    'background-color': '#262327',
                    margin: '10px',
                    transition: 'height .3s ease',
                    height: 0
                },
                draggable: false,
                droppable: false,
                stylable: false,
                copyable: false,
                selectable: false,
                hoverable: false,
                highlightable: false,
            },
            toHTML() {
                return '';
            }
        },
        view: {
            onRender({ el }) {
                setTimeout(() => el.style.height = 100 + 'px', 1);
            }
        }
    });

    mujo(editor, opts)
};