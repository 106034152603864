export default (editor, opts = {}) => {
    const $ = editor.$;
    const pfx = editor.getConfig().stylePrefix;
    const pn = editor.Panels;
    const template = $(`<div id="${pfx}pn-right" class="${pfx}pn-float ${pfx}one-bg"></div>`);
    const collapseBtn = $(`<div class="${pfx}collapse-btn open" style="width:25px;padding-right: 12px;"></div>`);
    const pnCommands = pn.getPanel('commands').view.$el;
    pnCommands.css('display', 'none'); // hide by default
    collapseBtn.on('click', function() {
        if (collapseBtn.hasClass('close')) {
            pnCommands.css('display', 'none');
        } else {
            pnCommands.css('display', '');
        }
        collapseBtn.toggleClass('close');
        collapseBtn.toggleClass('open');
    });
    template.append([collapseBtn, pnCommands]);
    return template;
}