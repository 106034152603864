const stopPropagation = e => e.stopPropagation();

export default (editor, opts = {}) => {
    const options = {
        ...{
            // CKEditor options
            options: {},

            // On which side of the element to position the toolbar
            // Available options: 'left|center|right'
            position: 'left',
        },
        ...opts
    }

    if (!CKEDITOR) {
        throw new Error('CKEDITOR instance not found');
    }

    editor.setCustomRte({
        enable(el, rte) {
            // If already exists I'll just focus on it
            if (rte && rte.status != 'destroyed') {
                this.focus(el, rte);
                return rte;
            }

            el.contentEditable = true;

            // Seems like 'sharedspace' plugin doesn't work exactly as expected
            // so will help hiding other toolbars already created
            let rteToolbar = editor.RichTextEditor.getToolbarEl();
            [].forEach.call(rteToolbar.children, (child) => {
                child.style.display = 'none';
            });

            // Check for the mandatory options
            const opt = options.options;
            const plgName = 'sharedspace';

            if (opt.extraPlugins) {
                if (typeof opt.extraPlugins === 'string')
                    opt.extraPlugins += ',' + plgName;
                else
                    opt.extraPlugins.push(plgName);
            } else {
                opt.extraPlugins = plgName;
            }

            if (!options.options.sharedSpaces) {
                options.options.sharedSpaces = { top: rteToolbar };
            }

            // Init CkEditors
            rte = CKEDITOR.inline(el, options.options);

            /**
             * Implement the `rte.getContent` method so that GrapesJS is able to retrieve CKE's generated content (`rte.getData`) properly
             *
             * See:
             *  - {@link https://github.com/artf/grapesjs/issues/2916}
             *  - {@link https://github.com/artf/grapesjs/blob/dev/src/dom_components/view/ComponentTextView.js#L80}
             *  - {@link https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_editor.html#method-getData}
             */
            rte.getContent = rte.getData;

            // Make click event propogate
            rte.on('contentDom', () => {
                const editable = rte.editable();
                editable.attachListener(editable, 'click', () => {
                    el.click();
                });
            });

            // The toolbar is not immediatly loaded so will be wrong positioned.
            // With this trick we trigger an event which updates the toolbar position
            rte.on('instanceReady', e => {
                const toolbar = rteToolbar.querySelector('#cke_' + rte.name);
                if (toolbar) {
                    toolbar.style.display = 'block';
                }
                editor.Canvas.getWindow().dispatchEvent(new CustomEvent('scroll'));
            });

            // Prevent blur when some of CKEditor's element is clicked
            rte.on('dialogShow', e => {
                const editorEls = grapesjs.$('.cke_dialog_background_cover, .cke_dialog');
                ['off', 'on'].forEach(m => editorEls[m]('mousedown', stopPropagation));
            });

            this.focus(el, rte);


            return rte;
        },

        disable(el, rte) {
            el.contentEditable = false;
            rte && rte.focusManager && rte.focusManager.blur(true);
            rte && rte.destroy(true);
        },

        focus(el, rte) {
            // Do nothing if already focused
            if (rte && rte.focusManager.hasFocus) {
                return;
            }
            el.contentEditable = true;
            rte && setTimeout(() => rte.focus(), 200);
        },
    });
}