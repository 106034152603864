export class Assets {
    constructor(editor, opts = { panelId: 'views-container' }) {
        this.editor = editor;
        this.$ = editor.$;
        this.pfx = editor.getConfig('stylePrefix');
        this.opts = opts;
        this.panelViews = this.findWithinEditor(`.${this.pfx}pn-views-container`);
        this.isShowing = true;
    }

    findPanel() {
        const pn = this.editor.Panels;
        const id = this.opts.panelId;
        const panel = pn.getPanel(id) || pn.addPanel({ id });
        return panel;
    }

    findWithinEditor(selector) {
        return this.$(selector, this.editor.getEl());
    }

    buildAssetsPanel() {
        const { $, editor, pfx } = this;
        const panel = this.findPanel();
        this.assetsPanel = $(`<div id="${pfx}pn-assets-container"><div><div style="padding-top: 14px; font-weight: 400; text-align: left">图片管理</div><p>图片搜索</p><div class="${pfx}assets-search"><i class="fa fa-search"></i><input style="color:white" type="search" placeholder="e.g img2, bg, bg-2"></div><div class="${pfx}upload-btns"><i class="fa fa-upload"></i></div></div><div class="${pfx}pn-assets"><div class="${pfx}assets-grid"></div></div></div>`);
        const input = this.assetsPanel.find('input');
        const uploadBtns = this.assetsPanel.find(`.${pfx}upload-btns`);
        const assetsGrid = this.assetsPanel.find(`.${pfx}assets-grid`);

        assetsGrid.html(updateAssets(editor));
        input.on('keyup', e => filter(editor, assetsGrid, e.target.value));
        uploadBtns.on('click', () => upload(editor));

        // Store, delete assets added by user
        editor.on('load asset:add asset:remove', () => assetsGrid.html(updateAssets(editor)));

        panel.set('appendContent', this.assetsPanel).trigger('change:appendContent');
    }

    showAssetsPanel() {
        this.isShowing = true;
        this.assetsPanel.css('display', 'block');
    }

    hideAssetsPanel() {
        if (this.assetsPanel) this.assetsPanel.css('display', 'none');
        this.isShowing = false;
    }
}

const filter = (editor, assetsGrid, query) => {
    const am = editor.Assets;
    const all = am.getAll();
    let filter = all;
    query && (filter = all.filter(asset => asset.get('src').split('/').pop().match(query) !== null)) ||
        (filter = all.filter(asset => true));
    am.render(filter);
    assetsGrid.html(updateAssets(editor));
};

const upload = editor => {
    editor.runCommand('open-assets', {
        target: null,
        types: ['image'], //extend if more asset types are added
        accept: 'image/*', //extend if more asset types are added
        onSelect: e => { } //do nothing
    });
};

const updateAssets = editor => {
    return editor.Assets.getAllVisible().map(asset => {
        return `<div class="img-container"><img src="${asset.get('src')}"><div class="htmlpage-pn-img-bg htmlpage-checker-bg"></div></div>`
    }).join('\n');
};