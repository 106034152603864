import styleAnimations from './styleAnimations';
import styleEase from './styleEase';
import styleTransform from './styleTransform';
import styleFilter from './styleFilter';
import styleBg from './styleBg';
import styleGp from './styleGpickr';
import { displayProp } from './display';
import { flexSect } from './flex';
import { typeAnimation, typeTransform, typeEase } from '../consts';

const overflow = [
    {
        value: 'visible',
        title: 'visible',
        className: 'fa fa-eye'
    },
    {
        value: 'hidden',
        title: 'hidden',
        className: 'fa fa-eye-slash'
    },
    {
        value: 'scroll',
        title: 'scroll',
        className: 'fa fa-arrows-v'
    },
    {
        value: 'auto',
        name: 'Auto'
    }
];
const borderStyle = {
    id: 'border-style-sub',
    property: 'border-style',
    type: 'select',
    default: 'solid',
    options: [
        { value: 'none' },
        { value: 'solid' },
        { value: 'dotted' },
        { value: 'dashed' },
        { value: 'double' },
        { value: 'groove' },
        { value: 'ridge' },
        { value: 'inset' },
        { value: 'outset' },
    ],
    full: 1,
};
const borderWidth = {
    id: 'border-width-sub',
    property: 'border-width',
    units: ['px', 'em', 'rem', 'vh', 'vw'],
    unit: 'px',
    type: 'slider',
    defaults: 1,
    min: 0,
    max: 20,
};
const borderColor = {
    id: 'border-color-sub',
    property: 'border-color',
    type: 'color'
};
const borderProps = [borderWidth, borderStyle, borderColor];
const outlineProps = [
    { ...borderWidth, ...{ id: 'outline-width', property: 'outline-width' } },
    { ...borderStyle, ...{ id: 'outline-style', property: 'outline-style' } },
    { ...borderColor, ...{ id: 'outline-color', property: 'outline-color' } }
]

export const basicSector = [
    {
        name: 'Layout',
        open: false,
        buildProps: ['display', 'float'],
        properties: [
            {
                property: 'float',
                type: 'radio',
                defaults: 'none',
                options: [
                    {
                        value: 'none',
                        className: 'fa fa-times'
                    },
                    {
                        value: 'left',
                        className: 'fa fa-long-arrow-left'
                    },
                    {
                        value: 'right',
                        className: 'fa fa-long-arrow-right'
                    }
                ],
            },
            displayProp,
        ],
    },
    {
        name: 'Dimensions',
        open: false,
        buildProps: ['width', 'height'],
        properties: [
            {
                type: 'slider',
                property: 'width',
                max: 1000,
            },
            {
                type: 'slider',
                property: 'height',
                max: 1000,
            }
        ]
    },
    {
        name: 'Spacing',
        open: false,
        properties: [
            {
                property: 'margin',
                type: 'slider',
                default: 0,
                units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
                fixedValues: ['initial', 'inherit', 'auto']
            },
            {
                property: 'padding',
                type: 'slider',
                default: 0,
                units: ['px', '%', 'em', 'rem', 'vh', 'vw'],
                fixedValues: ['initial', 'inherit', 'auto']
            }
        ]
    },
    {
        name: 'Typography',
        open: false,
        buildProps: [
            'font-family', 'font-weight', 'font-style', 'color', 'font-size', 'letter-spacing',
            'line-height', 'text-align', 'text-decoration',
        ],
        properties: [
            {
                property: 'text-align',
                type: 'radio',
                defaults: 'left',
                options: [
                    {
                        value: 'left',
                        className: 'fa fa-align-left'
                    },
                    {
                        value: 'center',
                        className: 'fa fa-align-center'
                    },
                    {
                        value: 'right',
                        className: 'fa fa-align-right'
                    },
                    {
                        value: 'justify',
                        className: 'fa fa-align-justify'
                    },
                ],
            },
            {
                property: 'text-decoration',
                type: 'radio',
                defaults: 'none',
                options: [
                    {
                        value: 'none',
                        name: 'None',
                        className: 'fa fa-times'
                    },
                    {
                        value: 'underline',
                        name: 'underline',
                        className: 'fa fa-underline'
                    },
                    {
                        value: 'line-through',
                        name: 'Line-through',
                        className: 'fa fa-strikethrough'
                    },
                    {
                        value: 'overline',
                        name: 'O'
                    }
                ],
            },
            {
                type: 'slider',
                property: 'font-size',
            },
            {
                type: 'slider',
                property: 'letter-spacing',
            },
            {
                type: 'slider',
                property: 'line-height',
            },
            {
                property: 'font-style',
                type: 'radio',
                defaults: 'normal',
                options: [
                    {
                        value: 'normal',
                        name: 'Normal',
                        className: 'fa fa-font'
                    },
                    {
                        value: 'italic',
                        name: 'Italic',
                        className: 'fa fa-italic'
                    }
                ],
            },
        ]
    },
    {
        name: 'Decorations',
        open: false,
        buildProps: ['background-color'],
        properties: [
            {
                type: 'slider',
                property: 'opacity',
                defaults: 1,
                step: 0.01,
                max: 1,
                min: 0,
            }
        ]
    },
    {
        name: 'Border',
        open: false,
        buildProps: ['border-radius'],
        properties: [
            {
                id: 'border',
                property: 'border',
                type: 'composite',
                properties: borderProps
            },
            {
                property: 'border-radius',
                properties: [
                    {
                        name: 'Top-left',
                        property: 'border-top-left-radius'
                    },
                    {
                        name: 'Top-right',
                        property: 'border-top-right-radius'
                    },
                    {
                        name: 'Bottom-left',
                        property: 'border-bottom-left-radius'
                    },
                    {
                        name: 'Bottom-right',
                        property: 'border-bottom-right-radius'
                    }
                ],
            },
        ]
    },
    {
        name: 'Filters',
        open: false,
        properties: [
            { extend: 'filter' },
        ],
    }
];

export const advancedSector = [
    {
        name: 'Layout',
        open: false,
        properties: [
            {
                name: 'Z-index',
                property: 'z-index',
                type: 'slider',
                defaults: 0
            },
            {
                property: 'visibility',
                type: 'radio',
                defaults: 'visible',
                options: [
                    {
                        value: 'visible',
                        className: 'fa fa-eye'
                    },
                    {
                        value: 'hidden',
                        className: 'fa fa-eye-slash'
                    }
                ],
            }
        ],
    },
    flexSect,
    {
        name: 'Dimensions',
        open: false,
        buildProps: ['min-width', 'max-width', 'overflow-x', 'min-height', 'max-height', 'overflow-y'],
        properties: [
            {
                type: 'slider',
                property: 'min-width',
                max: 1000,
            },
            {
                type: 'slider',
                property: 'max-width',
                max: 1000,
            },
            {
                property: 'overflow-x',
                type: 'radio',
                defaults: 'hidden',
                options: overflow
            },
            {
                type: 'slider',
                property: 'min-height',
                max: 1000,
            },
            {
                type: 'slider',
                property: 'max-height',
                max: 1000,
            },
            {
                property: 'overflow-y',
                type: 'radio',
                defaults: 'hidden',
                options: overflow
            }
        ]
    },
    {
        name: 'Position',
        open: false,
        buildProps: ['position', 'top', 'left', 'right', 'bottom'],
        properties: [
            {
                property: 'position',
                type: 'radio',
                defaults: 'static',
                options: [
                    {
                        value: 'static',
                    },
                    {
                        value: 'relative',
                    },
                    {
                        value: 'absolute',
                    },
                    {
                        value: 'fixed',
                    },
                    {
                        value: 'sticky',
                    }
                ],
            },
            {
                name: ' ',
                property: 'top'
            },
            {
                name: ' ',
                property: 'left'
            },
            {
                name: ' ',
                property: 'right'
            },
            {
                name: ' ',
                property: 'bottom'
            }
        ],
    },
    {
        name: 'Spacing',
        open: false,
        buildProps: ['margin', 'padding'],
        properties: [
            {
                property: 'margin',
                properties: [
                    {
                        name: ' ',
                        property: 'margin-top'
                    },
                    {
                        name: ' ',
                        property: 'margin-left'
                    },
                    {
                        name: ' ',
                        property: 'margin-right'
                    },
                    {
                        name: ' ',
                        property: 'margin-bottom'
                    }
                ],
                detached: true
            },
            {
                property: 'padding',
                properties: [
                    {
                        name: ' ',
                        property: 'padding-top'
                    },
                    {
                        name: ' ',
                        property: 'padding-left'
                    },
                    {
                        name: ' ',
                        property: 'padding-right'
                    },
                    {
                        name: ' ',
                        property: 'padding-bottom'
                    }
                ],
                detached: true
            }
        ]
    },
    {
        name: 'Typography',
        open: false,
        buildProps: ['vertical-align', 'text-shadow'],
        properties: [
            {
                property: 'text-shadow',
                properties: [
                    {
                        type: 'slider',
                        name: 'X position',
                        property: 'text-shadow-h'
                    },
                    {
                        type: 'slider',
                        name: 'Y position',
                        property: 'text-shadow-v'
                    },
                    {
                        name: 'Blur',
                        property: 'text-shadow-blur',
                        type: 'slider',
                    },
                    {
                        name: 'Color',
                        property: 'text-shadow-color'
                    }
                ],
            },
            {
                property: 'direction',
                type: 'radio',
                defaults: 'ltr',
                options: [
                    {
                        value: 'ltr',
                        title: 'left-to-right',
                        className: 'fa fa-long-arrow-right'
                    },
                    {
                        value: 'rtl',
                        name: 'right-to-left',
                        className: 'fa fa-long-arrow-left'
                    }
                ],
            },
            {
                property: 'vertical-align',
                type: 'select',
                defaults: 'baseline',
                full: 1,
                list: [
                    { value: 'baseline' },
                    { value: 'length' },
                    { value: 'sub' },
                    { value: 'super' },
                    { value: 'top' },
                    { value: 'text-top' },
                    { value: 'middle' },
                    { value: 'bottom' },
                    { value: 'text-bottom' },
                ],
            }
        ]
    },
    {
        name: 'Decorations',
        open: false,
        buildProps: ['cursor', 'opacity', 'box-shadow'],
        properties: [
            {
                property: 'cursor',
                type: 'radio',
                defaults: 'auto',
                options: [
                    {
                        value: 'auto',
                        className: 'fa fa-mouse-pointer'
                        //name: 'Auto'
                    },
                    {
                        value: 'pointer',
                        className: 'fa fa-hand-pointer-o'
                    },
                    {
                        value: 'copy',
                        className: 'fa fa-files-o'
                    },
                    {
                        value: 'crosshair',
                        className: 'fa fa-plus'
                    },
                    {
                        value: 'grab',
                        className: 'fa fa-hand-paper-o'
                    },
                    {
                        value: 'grabbing',
                        className: 'fa fa-hand-grab-o'
                    },
                    {
                        value: 'help',
                        className: 'fa fa-question'
                    },
                    {
                        value: 'move',
                        className: 'fa fa-arrows'
                    },
                    {
                        value: 'text',
                        className: 'fa fa-i-cursor'
                    },
                ],
            },
            {
                id: 'background-bg',
                property: 'background',
                type: 'bg',
                detached: false
            },
            {
                property: 'box-shadow',
                properties: [
                    {
                        name: 'X position',
                        property: 'box-shadow-h',
                        type: 'slider',
                        min: -50
                    },
                    {
                        name: 'Y position',
                        property: 'box-shadow-v',
                        type: 'slider',
                        min: -50
                    },
                    {
                        name: 'Blur',
                        property: 'box-shadow-blur',
                        type: 'slider',
                    },
                    {
                        name: 'Spread',
                        property: 'box-shadow-spread',
                        type: 'slider',
                    },
                    {
                        name: 'Color',
                        property: 'box-shadow-color'
                    },
                    {
                        name: 'Shadow type',
                        property: 'box-shadow-type',
                        type: 'radio'
                    }
                ]
            }
        ]
    },
    {
        name: 'Border',
        open: false,
        properties: [
            {
                id: 'outline',
                property: 'outline',
                type: 'composite',
                properties: outlineProps
            },
            {
                id: 'border-top',
                property: 'border-top',
                type: 'composite',
                properties: borderProps
            },
            {
                id: 'border-right',
                property: 'border-right',
                type: 'composite',
                properties: borderProps
            },
            {
                id: 'border-bottom',
                property: 'border-bottom',
                type: 'composite',
                properties: borderProps
            },
            {
                id: 'border-left',
                property: 'border-left',
                type: 'composite',
                properties: borderProps
            },
            {
                property: 'border-collapse',
                type: 'radio',
                defaults: 'collapse',
                options: [
                    {
                        name: 'Yes',
                        value: 'collapse'
                    },
                    {
                        name: 'No',
                        value: 'separate'
                    }
                ],
            }
        ]
    },
    {
        name: 'Filters',
        open: false,
        properties: [
            { extend: 'filter' },
            { extend: 'filter', property: 'backdrop-filter' },
        ],
    },
    {
        name: 'Transforms',
        open: false,
        properties: [
            { extend: typeTransform },
        ],
    }
];

export const extraSector = [
    {
        name: 'Transitions',
        open: false,
        buildProps: ['transition'],
        properties: [
            {
                property: 'transition',
                properties: [
                    {
                        name: 'Property',
                        property: 'transition-property',
                        full: 1
                    },
                    {
                        name: 'Easing',
                        functionName: 'cubic-bezier',
                        fixedValues: ['ease', 'ease-in', 'ease-out', 'ease-in-out', 'linear'],
                        property: 'transition-timing-function',
                        type: typeEase,
                        defaults: 'ease'
                    },
                    {
                        name: 'Duration',
                        type: 'slider',
                        property: 'transition-duration',
                        min: 0,
                        max: 100,
                    },
                    {
                        name: 'Delay',
                        units: ['s'],
                        type: 'slider',
                        property: 'transition-delay',
                        min: 0,
                        max: 100,
                    }
                ],
            }
        ]
    },
    {
        name: 'Animations',
        open: false,
        properties: [
            {
                name: 'Animation',
                property: 'animation',
                type: 'stack',
                properties: [
                    {
                        name: 'Name',
                        property: 'animation-name',
                        type: typeAnimation,
                        defaults: 'bounce',
                        full: 1
                    },
                    {
                        name: 'Duration',
                        type: 'slider',
                        property: 'animation-duration',
                        defaults: '2s',
                        units: ['s'],
                        min: 0,
                        max: 100,
                    },
                    {
                        name: 'Easing',
                        functionName: 'cubic-bezier',
                        fixedValues: ['ease', 'ease-in', 'ease-out', 'ease-in-out', 'linear'],
                        type: typeEase,
                        defaults: 'ease',
                        property: 'animation-timing-function'
                    },
                    {
                        name: 'Delay',
                        units: ['s'],
                        type: 'slider',
                        property: 'animation-delay',
                        min: 0,
                        max: 100,
                    },
                    {
                        name: 'Iteration',
                        type: 'integer',
                        defaults: 'infinite',
                        fixedValues: ['infinite'],
                        list: [{
                            value: 'infinite'
                        }],
                        property: 'animation-iteration-count',
                    },
                    {
                        name: 'Direction',
                        type: 'select',
                        defaults: 'normal',
                        property: 'animation-direction',
                        list: [
                            {
                                value: 'normal'
                            },
                            {
                                value: 'reverse'
                            },
                            {
                                value: 'alternate'
                            },
                            {
                                value: 'alternate-reverse',
                            }
                        ]
                    },
                    {
                        name: 'Fill',
                        type: 'select',
                        defaults: 'none',
                        full: 1,
                        property: 'animation-fill-mode',
                        list: [
                            {
                                value: 'none'
                            },
                            {
                                value: 'forwards'
                            },
                            {
                                value: 'backwards'
                            },
                            {
                                value: 'both'
                            }
                        ]
                    },
                    {
                        name: 'State',
                        type: 'radio',
                        defaults: 'running',
                        property: 'animation-play-state',
                        options: [
                            {
                                value: 'running',
                                className: 'fa fa-play'
                            },
                            {
                                value: 'paused',
                                className: 'fa fa-pause'
                            }
                        ]
                    }
                ],
            }
        ]
    }
];

export default (editor, opts = {}) => {
    const sm = editor.Styles;

    //init style types
    styleAnimations(editor, opts.animOpts);
    styleTransform(editor);
    styleFilter(editor);
    window.cubicBezier && styleEase(editor, opts.easeOpts, opts.showPanels);
    window.GPickr && styleGp(editor, opts.gpOpts);
    styleBg(editor, opts.bgOpts);

    sm.getSectors().reset(basicSector);
}
