export const displayProp = {
    property: 'display',
    type: 'radio',
    defaults: 'block',
    options: [
        {
            value: 'none',
            title: 'none',
            name: 'none',
            className: 'fa fa-times'
        },
        {
            value: 'block',
            title: 'block',
            name: 'block',
            className: 'icons-flex icon-disp-block'
        },
        {
            value: 'inline',
            title: 'inline',
            name: 'inline',
            className: 'fa fa-paragraph'
        },
        {
            value: 'inline-block',
            title: 'inline-block',
            name: 'inline-block',
            className: 'icons-flex icon-disp-iblock'
        },
        {
            value: 'flex',
            title: 'flex',
            name: 'flex',
            className: 'icons-flex icon-disp-flex'
        },
        {
            value: 'grid',
            title: 'grid',
            name: 'grid',
            className: 'icons-flex icon-disp-grid'
        }
    ],
}